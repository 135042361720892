<template>
    <nav class="navbar navbar-expand-lg fixed-top bg-light" id="nav">
        <div class="container">
            <router-link class="navbar-brand" to="/" exact><img src="../assets/logo.png" alt="" style="width: 80px;"></router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/" exact>Empleos</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/empresa">Empresa</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contacto">Contacto</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>

export default {
    data() {
        return {
        }
    },
    props: {

    },
    methods: {

    },
    mounted() {
        this.$nextTick(function () {
            window.addEventListener("scroll", function () {
                var navbar = document.getElementById("nav")
                var nav_classes = navbar.classList
                if (document.documentElement.scrollTop >= 150) {
                    if (nav_classes.contains("shrink") === false) {
                        nav_classes.toggle("shrink");
                    }
                }
                else {
                    if (nav_classes.contains("shrink") === true) {
                        nav_classes.toggle("shrink");
                    }
                }
            })
        })
    },
}
</script>
<style scoped>
nav {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.bg-light {
    background-color: white !important;
}
</style>
    
    