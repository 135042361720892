import axios from "axios";


async function isAuthenticatedGuard(to, from, next){
  if (localStorage.getItem('auth_token') || localStorage.getItem('user_id')) {
    try {
      const response = await axios.get('https://empleos123rh.com/v1/api/auth/checktoken', { headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` } });
      if (response.data.status === 200) {
        next();
      }
    } catch (error) {
      if (error.response.data.status === 404) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('auth_token');
        next({ name: 'home' });
      }
    }
     }else{
       localStorage.removeItem('user_id');
       localStorage.removeItem('auth_token');
       next({ name: 'home' });
     }
}

export default isAuthenticatedGuard
