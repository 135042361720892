import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import isAuthenticatedGuard from './auth-guard';
import isAuthGuard from './redirect-guard';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/empresa',
    name: 'empresa',
    component: () => import(/* webpackChunkName: "empresa" */ '../views/EmpresaView.vue')
  },
  {
    path: '/categoria/:cat',
    name: 'categoria',
    component: () => import(/* webpackChunkName: "categoria" */ '../views/CatView.vue')
  },
  {
    path: '/zona/:estado/:ciudad',
    name: 'zona',
    component: () => import(/* webpackChunkName: "zona" */ '../views/ZonaView.vue')
  },
  {
    path: '/reset-password/:id/:token',
    name: 'reset',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetView.vue')
  },
  {
    path: '/empleo/:id',
    name: 'empleo',
    component: () => import(/* webpackChunkName: "empleo" */ '../views/EmpleoView.vue')
  },
  {
    path: '/empresa/login',
    name: 'empresaLogin',
    beforeEnter: [ isAuthGuard ],
    component: () => import(/* webpackChunkName: "empresaLogin" */ '../views/LoginView.vue')
  },
  {
    path: '/empresa/registro',
    name: 'empresaRegistro',
    component: () => import(/* webpackChunkName: "empresaRegistro" */ '../views/RegisterView.vue')
  },
  {
    path: '/recoverypass',
    name: 'recoverypass',
    component: () => import(/* webpackChunkName: "recoverypass" */ '../views/RecoveryView.vue')
  },
  {
    path: '/terminoscondiciones',
    name: 'terminoscondiciones',
    component: () => import(/* webpackChunkName: "terminoscondiciones" */ '../views/TerminoscondicionesView.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "terminoscondiciones" */ '../views/ContactoView.vue')
  },
  {
    path: '/empresa/dashboard',
    name: 'empresaDashboard',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "empresaDashboard" */ '../views/DashboardView.vue')
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import(/* webpackChunkName: "Error404" */ '../views/Error404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
