<template>
  <NavbarComp />
  <a class="fixedButton" href="https://wa.link/26rb8n">
    <div class="roundedFixedBtn"><i class="bi bi-whatsapp"></i></div>
  </a>
  <header class="masthead">
    <div class="container position-relative">
      <div class="row justify-content-center">
        <div class="col-xl-6">
          <div class="text-center text-white">
            <!-- Page heading-->
            <h3 class="mb-5">Encuentra tu trabajo ideal en el lugar ideal.</h3>
            <div class="card search">
              <div class="card-body">
                <form class="form-subscribe" @submit.prevent="zona">
                  <div class="row">
                    <div class="col">
                      <div class="row g-3">
                        <div class="col-6">
                          <select class="form-select search" v-model="estado" @change="getciudades" required>
                            <option value="" disabled>Entidad</option>
                            <option>Aguascalientes</option>
                            <option>Baja California</option>
                            <option>Baja California Sur</option>
                            <option>Campeche</option>
                            <option>Chiapas</option>
                            <option>Chihuahua</option>
                            <option>Ciudad de México</option>
                            <option>Coahuila</option>
                            <option>Colima</option>
                            <option>Durango</option>
                            <option>Estado de México</option>
                            <option>Guanajuato</option>
                            <option>Guerrero</option>
                            <option>Hidalgo</option>
                            <option>Jalisco</option>
                            <option>Michoacán</option>
                            <option>Morelos</option>
                            <option>Nayarit</option>
                            <option>Nuevo León</option>
                            <option>Oaxaca</option>
                            <option>Puebla</option>
                            <option>Querétaro</option>
                            <option>Quintana Roo</option>
                            <option>San Luis Potosí</option>
                            <option>Sinaloa</option>
                            <option>Sonora</option>
                            <option>Tabasco</option>
                            <option>Tamaulipas</option>
                            <option>Tlaxcala</option>
                            <option>Veracruz</option>
                            <option>Yucatán</option>
                            <option>Zacatecas</option>
                          </select>
                        </div>
                        <div class="col-6">
                          <select class="form-select search" v-model="cxe" required>
                            <option value="" disabled>Ciudad</option>
                            <option v-for="ciudad in ciudades">{{ ciudad.nombre }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto"><button class="btn btn-danger" type="submit">Buscar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="pb-5" id="cat">
    <div class="container">
      <h3 class="text-center pt-5 blanco">CATEGORÍAS</h3>
      <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5">
          <div class="col-lg-12 col-md-12 text-center">
            <div class="mt-5">
              <form @submit.prevent="search_cat" style="width: 350px" class="mx-auto">
                <select class="form-select search" v-model="cat" required>
                  <option value="" disabled>Selecciona Categoría</option>
                  <option>Administración, Oficina</option>
                  <option>Administrativo</option>
                  <option>Almacén, Logística, Transporte</option>
                  <option>Atención a clientes</option>
                  <option>CallCenter, Telemercadeo</option>
                  <option>Compras, Comercio Exterior</option>
                  <option>Construcción y obra</option>
                  <option>Contabilidad, Finanzas</option>
                  <option>Dirección, Gerencia</option>
                  <option>Diseño, Artes gráficas</option>
                  <option>Docencia</option>
                  <option>Hostelería, Turismo</option>
                  <option>Informatica, Telecomunicaciones</option>
                  <option>Ingeniería</option>
                  <option>Investigación y Calidad</option>
                  <option>Legal, Asesoría</option>
                  <option>Mantenimiento y Reparaciones Técnicas</option>
                  <option>Medicina, Salud</option>
                  <option>Mercadotécnia, Publicidad, Comunicación</option>
                  <option>Producción, Operarios, Manufactura</option>
                  <option>Recursos Humanos</option>
                  <option>Restaurantes, alimentos y bebidas</option>
                  <option>Servicios Generales, Aseo y Seguridad</option>
                  <option>Ventas</option>
                </select>
                <div class="mt-5">
                  <button class="btn btn-light" type="submit">Buscar categoría</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5">
    <div class="container">
      <div class="row" style="align-items: center;">
        <div class="col-md-6">
          <h3>Simplificamos el trámite de solicitudes de manera <br>Veloz, protegido y confiable</h3>
          <p>Obtén un trabajo desde la comodidad de tu hogar, descubre una amplia gama de compañías interesadas en tu perfil</p>
        </div>
        <div class="col-md-6">
          <img src="../assets/info.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5">
    <div class="container text-center">
      <div class="row" style="align-items: center;">
        <div class="col-md-12">
          <h3>Suscribete y encuentra a los mejores candidatos</h3>
            <p>1. Realiza el pago</p>
            <p style="margin-top: -1rem;">2. Contáctanos en Whatsapp</p>
            <p style="margin-top: -1rem;">3. Creamos tu cuenta y estáras listo para utilizar nuestra plataforma.</p>
          <a href="https://clip.mx/@www.empleos123rh.com" class="btn btn-danger mb-5 px-5 py-2" type="submit">Suscribirse</a>
        </div>
      </div>
    </div>
  </section>
  <section id="reciente" class="pb-5">
    <div class="container">
      <h3 class="text-center pt-5">OFERTAS RECIENTES</h3>

      <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5">

          <div class="col-lg-3 col-md-6 text-center" v-for="item in items">
            <div class="mt-5">
              <div class="card w-100 cp" @click="empleo(item.uuid)">
                <div class="card-body">
                  <h5 class="card-title">{{ item.titulo }}</h5>
                  <p class="card-text">{{ item.salario }}</p>
                  <p class="card-text text-start">{{ item.estado }}, {{ item.ciudad }}</p>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  </section>



  <footer class="text-center">
    <div class="row w-100 px-5 py-3">
    <div class="col">
            ©empleos123rh 2023.
    </div>
    <div class="col">
      <router-link to="/terminoscondiciones">Terminos & Condiciones.</router-link>
    </div>
  </div>
  </footer>
  
</template>

<script>

import NavbarComp from '@/components/NavbarComp.vue';
import axios from "axios";
import { useHead } from '@unhead/vue'
useHead({
  title: 'Empleosrh123 | Inicio',
  meta: [
    {
      name: 'description',
      content: 'Explora miles de oportunidades laborales en nuestra página web de empleos. Conéctate con empresas que buscan candidatos talentosos en diversos sectores y niveles de experiencia. Encuentra el trabajo de tus sueños y da un paso adelante en tu carrera profesional. Regístrate ahora y comienza a construir o fortalecer tu empresa.',
    },
  ],
})
export default {
  components: {
    NavbarComp,
  },
  data() {
    return {
      estado: '',
      ciudades: [],
      cxe: '',
      cat: '',
      items: []
    }
  },
  methods: {
    getciudades() {

      if (this.estado === 'Aguascalientes') {
        this.cxe = '';
        this.ciudades = [{ nombre: 'Aguascalientes' },
        { nombre: 'Asientos' },
        { nombre: 'Calvillo' },
        { nombre: 'Cosío' },
        { nombre: 'Jesús Maria' },
        { nombre: 'Pabellón de Arteaga' },
        { nombre: 'Rincón de Romos' },
        { nombre: 'San José de Gracia' },
        { nombre: 'Tepezalá' },
        { nombre: 'San Francisco de los Romo' },
        { nombre: 'El Llano' }
        ]
      }

      if (this.estado === 'Baja California') {
        this.cxe = '';
        this.ciudades = [{ nombre: 'Ensenada' },
        { nombre: 'Mexicali' },
        { nombre: 'Tecate' },
        { nombre: 'Tijuana' },
        { nombre: 'Playas de Rosarito' },
        { nombre: 'San Quintín' },
        { nombre: 'San Felipe' }
        ]
      }

      if (this.estado === 'Baja California Sur') {
        this.cxe = '';
        this.ciudades = [{ nombre: 'Comondú' },
        { nombre: 'Mulegé' },
        { nombre: 'La Paz' },
        { nombre: 'Los Cabos' },
        { nombre: 'Loreto' }
        ]
      }

      if (this.estado === 'Campeche') {
        this.cxe = '';
        this.ciudades = [{ nombre: 'Calkiní' },
        { nombre: 'Campeche' },
        { nombre: 'Carmen' },
        { nombre: 'Champotón' },
        { nombre: 'Hecelchakán' },
        { nombre: 'Hopelchén' },
        { nombre: 'Palizada' },
        { nombre: 'Tenabo' },
        { nombre: 'Escárcega' },
        { nombre: 'Calakmul' },
        { nombre: 'Candelaria' },
        { nombre: 'Seybaplaya' },
        { nombre: 'Dzitbalché' }
        ]
      }

      if (this.estado === 'Chiapas') {
        this.cxe = '';
        this.ciudades = [{ nombre: 'Acacoyagua' },
        { nombre: 'Acala' },
        { nombre: 'Acapetahua' },
        { nombre: 'Altamirano' },
        { nombre: 'Amatán' },
        { nombre: 'Amatenango de la Frontera' },
        { nombre: 'Amatenango del Valle' },
        { nombre: 'Ángel Albino Corzo' },
        { nombre: 'Arriaga' },
        { nombre: 'Bejucal de Ocampo' },
        { nombre: 'Bella Vista' },
        { nombre: 'Berriozábal' },
        { nombre: 'Bochil' },
        { nombre: 'El Bosque' },
        { nombre: 'Cacahoatán' },
        { nombre: 'Catazajá' },
        { nombre: 'Cintalapa' },
        { nombre: 'Coapilla' },
        { nombre: 'Comitán de Domínguez' },
        { nombre: 'La Concordia' },
        { nombre: 'Copainalá' },
        { nombre: 'Chalchihuitán' },
        { nombre: 'Chamula' },
        { nombre: 'Chanal' },
        { nombre: 'Chapultenango' },
        { nombre: 'Chenalhó' },
        { nombre: 'Chiapa de Corzo' },
        { nombre: 'Chiapilla' },
        { nombre: 'Chicoasén' },
        { nombre: 'Chicomuselo' },
        { nombre: 'Chilón' },
        { nombre: 'Escuintla' },
        { nombre: 'Francisco León' },
        { nombre: 'Frontera Comalapa' },
        { nombre: 'Frontera Hidalgo' },
        { nombre: 'La Grandeza' },
        { nombre: 'Huehuetán' },
        { nombre: 'Huixtán' },
        { nombre: 'Huitiupán' },
        { nombre: 'Huixtla' },
        { nombre: 'La Independencia' },
        { nombre: 'Ixhuatán' },
        { nombre: 'Ixtacomitán' },
        { nombre: 'Ixtapa' },
        { nombre: 'Ixtapangajoya' },
        { nombre: 'Jiquipilas' },
        { nombre: 'Jitotol' },
        { nombre: 'Juárez' },
        { nombre: 'Larráinzar' },
        { nombre: 'La Libertad' },
        { nombre: 'Mapastepec' },
        { nombre: 'Las Margaritas' },
        { nombre: 'Mazapa de Madero' },
        { nombre: 'Mazatán' },
        { nombre: 'Metapa' },
        { nombre: 'Mitontic' },
        { nombre: 'Motozintla' },
        { nombre: 'Nicolás Ruíz' },
        { nombre: 'Ocosingo' },
        { nombre: 'Ocotepec' },
        { nombre: 'Ocozocoautla de Espinosa' },
        { nombre: 'Ostuacán' },
        { nombre: 'Osumacinta' },
        { nombre: 'Oxchuc' },
        { nombre: 'Palenque' },
        { nombre: 'Pantelhó' },
        { nombre: 'Pantepec' },
        { nombre: 'Pichucalco' },
        { nombre: 'Pijijiapan' },
        { nombre: 'El Porvenir' },
        { nombre: 'Villa Comaltitlán' },
        { nombre: 'Pueblo Nuevo Solistahuacán' },
        { nombre: 'Rayón' },
        { nombre: 'Reforma' },
        { nombre: 'Las Rosas' },
        { nombre: 'Sabanilla' },
        { nombre: 'Salto de Agua' },
        { nombre: 'San Cristóbal de las Casas' },
        { nombre: 'San Fernando' },
        { nombre: 'Siltepec' },
        { nombre: 'Simojovel' },
        { nombre: 'Sitalá' },
        { nombre: 'Socoltenango' },
        { nombre: 'Solosuchiapa' },
        { nombre: 'Soyaló' },
        { nombre: 'Suchiapa' },
        { nombre: 'Suchiate' },
        { nombre: 'Sunuapa' },
        { nombre: 'Tapachula' },
        { nombre: 'Tapalapa' },
        { nombre: 'Tapilula' },
        { nombre: 'Tecpatán' },
        { nombre: 'Tenejapa' },
        { nombre: 'Teopisca' },
        { nombre: 'Tila' },
        { nombre: 'Tonalá' },
        { nombre: 'Totolapa' },
        { nombre: 'La Trinitaria' },
        { nombre: 'Tumbalá' },
        { nombre: 'Tuxtla Gutiérrez' },
        { nombre: 'Tuxtla Chico' },
        { nombre: 'Tuzantán' },
        { nombre: 'Tzimol' },
        { nombre: 'Unión Juárez' },
        { nombre: 'Venustiano Carranza' },
        { nombre: 'Villa Corzo' },
        { nombre: 'Villaflores' },
        { nombre: 'Yajalón' },
        { nombre: 'San Lucas' },
        { nombre: 'Zinacantán' },
        { nombre: 'San Juan Cancuc' },
        { nombre: 'Aldama' },
        { nombre: 'Benemérito de las Américas' },
        { nombre: 'Maravilla Tenejapa' },
        { nombre: 'Marqués de Comillas' },
        { nombre: 'Montecristo de Guerrero' },
        { nombre: 'San Andrés Duraznal' },
        { nombre: 'Santiago el Pinar' },
        { nombre: 'Capitán Luis Ángel Vidal' },
        { nombre: 'Rincón Chamula San Pedro' },
        { nombre: 'Emiliano Zapata' },
        { nombre: 'Mezcalapa' },
        { nombre: 'El Parral' },
        { nombre: 'Honduras de la Sierra' }
        ]
      }

      if (this.estado === 'Chihuahua') {
        this.cxe = '';
        this.ciudades = [{ nombre: 'Ahumada' },
        { nombre: 'Aldama' },
        { nombre: 'Allende' },
        { nombre: 'Aquiles Serdán' },
        { nombre: 'Ascensión' },
        { nombre: 'Bachíniva' },
        { nombre: 'Balleza' },
        { nombre: 'Batopilas de Manuel Gómez Morín' },
        { nombre: 'Bocoyna' },
        { nombre: 'Buenaventura' },
        { nombre: 'Camargo' },
        { nombre: 'Carichí' },
        { nombre: 'Casas Grandes' },
        { nombre: 'Coronado' },
        { nombre: 'Coyame del Sotol' },
        { nombre: 'La Cruz' },
        { nombre: 'Cuauhtémoc' },
        { nombre: 'Cusihuiriachi' },
        { nombre: 'Chihuahua' },
        { nombre: 'Chínipas' },
        { nombre: 'Delicias' },
        { nombre: 'Dr. Belisario Domínguez' },
        { nombre: 'Galeana' },
        { nombre: 'Santa Isabel' },
        { nombre: 'Gómez Farías' },
        { nombre: 'Gran Morelos' },
        { nombre: 'Guachochi' },
        { nombre: 'Guadalupe' },
        { nombre: 'Guadalupe y Calvo' },
        { nombre: 'Guazapares' },
        { nombre: 'Guerrero' },
        { nombre: 'Hidalgo del Parral' },
        { nombre: 'Huejotitán' },
        { nombre: 'Ignacio Zaragoza' },
        { nombre: 'Janos' },
        { nombre: 'Jiménez' },
        { nombre: 'Juárez' },
        { nombre: 'Julimes' },
        { nombre: 'López' },
        { nombre: 'Madera' },
        { nombre: 'Maguarichi' },
        { nombre: 'Manuel Benavides' },
        { nombre: 'Matachí' },
        { nombre: 'Matamoros' },
        { nombre: 'Meoqui' },
        { nombre: 'Morelos' },
        { nombre: 'Moris' },
        { nombre: 'Namiquipa' },
        { nombre: 'Nonoava' },
        { nombre: 'Nuevo Casas Grandes' },
        { nombre: 'Ocampo' },
        { nombre: 'Ojinaga' },
        { nombre: 'Práxedis G. Guerrero' },
        { nombre: 'Riva Palacio' },
        { nombre: 'Rosales' },
        { nombre: 'Rosario' },
        { nombre: 'San Francisco de Borja' },
        { nombre: 'San Francisco de Conchos' },
        { nombre: 'San Francisco del Oro' },
        { nombre: 'Santa Bárbara' },
        { nombre: 'Satevó' },
        { nombre: 'Saucillo' },
        { nombre: 'Temósachic' },
        { nombre: 'El Tule' },
        { nombre: 'Urique' },
        { nombre: 'Uruachi' },
        { nombre: 'Valle de Zaragoza' }
        ]
      }

      if (this.estado === 'Ciudad de México') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Azcapotzalco' },
          { nombre: 'Coyoacán' },
          { nombre: 'Cuajimalpa de Morelos' },
          { nombre: 'Gustavo A. Madero' },
          { nombre: 'Iztacalco' },
          { nombre: 'Iztapalapa' },
          { nombre: 'La Magdalena Contreras' },
          { nombre: 'Milpa Alta' },
          { nombre: 'Álvaro Obregón' },
          { nombre: 'Tláhuac' },
          { nombre: 'Tlalpan' },
          { nombre: 'Xochimilco' },
          { nombre: 'Benito Juárez' },
          { nombre: 'Cuauhtémoc' },
          { nombre: 'Miguel Hidalgo' },
          { nombre: 'Venustiano Carranza' }
        ]
      }

      if (this.estado === 'Coahuila') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Abasolo' },
          { nombre: 'Acuña' },
          { nombre: 'Allende' },
          { nombre: 'Arteaga' },
          { nombre: 'Candela' },
          { nombre: 'Castaños' },
          { nombre: 'Cuatrociénegas' },
          { nombre: 'Escobedo' },
          { nombre: 'Francisco I. Madero' },
          { nombre: 'Frontera' },
          { nombre: 'General Cepeda' },
          { nombre: 'Guerrero' },
          { nombre: 'Hidalgo' },
          { nombre: 'Jiménez' },
          { nombre: 'Juárez' },
          { nombre: 'Lamadrid' },
          { nombre: 'Matamoros' },
          { nombre: 'Monclova' },
          { nombre: 'Morelos' },
          { nombre: 'Múzquiz' },
          { nombre: 'Nadadores' },
          { nombre: 'Nava' },
          { nombre: 'Ocampo' },
          { nombre: 'Parras' },
          { nombre: 'Piedras Negras' },
          { nombre: 'Progreso' },
          { nombre: 'Ramos Arizpe' },
          { nombre: 'Sabinas' },
          { nombre: 'Sacramento' },
          { nombre: 'Saltillo' },
          { nombre: 'San Buenaventura' },
          { nombre: 'San Juan de Sabinas' },
          { nombre: 'San Pedro' },
          { nombre: 'Sierra Mojada' },
          { nombre: 'Torreón' },
          { nombre: 'Viesca' },
          { nombre: 'Villa Unión' },
          { nombre: 'Zaragoza' }
        ]
      }

      if (this.estado === 'Colima') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Armería' },
          { nombre: 'Colima' },
          { nombre: 'Comala' },
          { nombre: 'Coquimatlán' },
          { nombre: 'Cuauhtémoc' },
          { nombre: 'Ixtlahuacán' },
          { nombre: 'Manzanillo' },
          { nombre: 'Minatitlán' },
          { nombre: 'Tecomán' },
          { nombre: 'Villa de Álvarez' }
        ]
      }

      if (this.estado === 'Durango') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Canatlán' },
          { nombre: 'Canelas' },
          { nombre: 'Coneto de Comonfort' },
          { nombre: 'Cuencamé' },
          { nombre: 'Durango' },
          { nombre: 'El Oro' },
          { nombre: 'Gómez Palacio' },
          { nombre: 'Gral. Simón Bolívar' },
          { nombre: 'Guadalupe Victoria' },
          { nombre: 'Guanaceví' },
          { nombre: 'Hidalgo' },
          { nombre: 'Indé' },
          { nombre: 'Lerdo' },
          { nombre: 'Mapimí' },
          { nombre: 'Mezquital' },
          { nombre: 'Nazas' },
          { nombre: 'Nombre de Dios' },
          { nombre: 'Nuevo Ideal' },
          { nombre: 'Ocampo' },
          { nombre: 'Otáez' },
          { nombre: 'Pánuco de Coronado' },
          { nombre: 'Peñón Blanco' },
          { nombre: 'Poanas' },
          { nombre: 'Pueblo Nuevo' },
          { nombre: 'Rodeo' },
          { nombre: 'San Bernardo' },
          { nombre: 'San Dimas' },
          { nombre: 'San Juan de Guadalupe' },
          { nombre: 'San Juan del Río' },
          { nombre: 'San Luis del Cordero' },
          { nombre: 'San Pedro del Gallo' },
          { nombre: 'Santa Clara' },
          { nombre: 'Santiago Papasquiaro' },
          { nombre: 'Súchil' },
          { nombre: 'Tamazula' },
          { nombre: 'Tepehuanes' },
          { nombre: 'Tlahualilo' },
          { nombre: 'Topia' },
          { nombre: 'Vicente Guerrero' }
        ]
      }

      if (this.estado === 'Estado de México') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acambay' },
          { nombre: 'Acolman' },
          { nombre: 'Aculco' },
          { nombre: 'Almoloya de Alquisiras' },
          { nombre: 'Almoloya de Juárez' },
          { nombre: 'Almoloya del Río' },
          { nombre: 'Amanalco' },
          { nombre: 'Amatepec' },
          { nombre: 'Amecameca' },
          { nombre: 'Apaxco' },
          { nombre: 'Atenco' },
          { nombre: 'Atizapán' },
          { nombre: 'Atizapán de Zaragoza' },
          { nombre: 'Atlacomulco' },
          { nombre: 'Atlautla' },
          { nombre: 'Axapusco' },
          { nombre: 'Ayapango' },
          { nombre: 'Calimaya' },
          { nombre: 'Capulhuac' },
          { nombre: 'Chalco' },
          { nombre: 'Chapa de Mota' },
          { nombre: 'Chapultepec' },
          { nombre: 'Chiautla' },
          { nombre: 'Chicoloapan' },
          { nombre: 'Chiconcuac' },
          { nombre: 'Chimalhuacán' },
          { nombre: 'Coacalco de Berriozábal' },
          { nombre: 'Coatepec Harinas' },
          { nombre: 'Cocotitlán' },
          { nombre: 'Coyotepec' },
          { nombre: 'Cuautitlán' },
          { nombre: 'Cuautitlán Izcalli' },
          { nombre: 'Donato Guerra' },
          { nombre: 'Ecatepec de Morelos' },
          { nombre: 'Ecatzingo' },
          { nombre: 'El Oro' },
          { nombre: 'Huehuetoca' },
          { nombre: 'Hueypoxtla' },
          { nombre: 'Huixquilucan' },
          { nombre: 'Isidro Fabela' },
          { nombre: 'Ixtapaluca' },
          { nombre: 'Ixtapan de la Sal' },
          { nombre: 'Ixtapan del Oro' },
          { nombre: 'Ixtlahuaca' },
          { nombre: 'Jaltenco' },
          { nombre: 'Jilotepec' },
          { nombre: 'Jilotzingo' },
          { nombre: 'Jiquipilco' },
          { nombre: 'Jocotitlán' },
          { nombre: 'Joquicingo' },
          { nombre: 'Juchitepec' },
          { nombre: 'La Paz' },
          { nombre: 'Lerma' },
          { nombre: 'Luvianos' },
          { nombre: 'Malinalco' },
          { nombre: 'Melchor Ocampo' },
          { nombre: 'Metepec' },
          { nombre: 'Mexicaltzingo' },
          { nombre: 'Morelos' },
          { nombre: 'Naucalpan de Juárez' },
          { nombre: 'Nextlalpan' },
          { nombre: 'Nezahualcoyotl' },
          { nombre: 'Nicolás Romero' },
          { nombre: 'Nopaltepec' },
          { nombre: 'Ocoyoacac' },
          { nombre: 'Ocuilan' },
          { nombre: 'Otumba' },
          { nombre: 'Otzoloapan' },
          { nombre: 'Otzolotepec' },
          { nombre: 'Ozumba' },
          { nombre: 'Papalotla' },
          { nombre: 'Polotitlán' },
          { nombre: 'Rayón' },
          { nombre: 'San Antonio la Isla' },
          { nombre: 'San Felipe del Progreso' },
          { nombre: 'San José del Rincón' },
          { nombre: 'San Martín de las Pirámides' },
          { nombre: 'San Mateo Atenco' },
          { nombre: 'San Simón de Guerrero' },
          { nombre: 'Santo Tomás' },
          { nombre: 'Soyaniquilpan de Juárez' },
          { nombre: 'Sultepec' },
          { nombre: 'Tecámac' },
          { nombre: 'Tejupilco' },
          { nombre: 'Temamatla' },
          { nombre: 'Temascalapa' },
          { nombre: 'Temascalcingo' },
          { nombre: 'Temascaltepec' },
          { nombre: 'Temoaya' },
          { nombre: 'Tenancingo' },
          { nombre: 'Tenango del Aire' },
          { nombre: 'Tenango del Valle' },
          { nombre: 'Teoloyucán' },
          { nombre: 'Teotihuacán' },
          { nombre: 'Tepetlaoxtoc' },
          { nombre: 'Tepetlixpa' },
          { nombre: 'Tepotzotlán' },
          { nombre: 'Tequixquiac' },
          { nombre: 'Texcaltitlán' },
          { nombre: 'Texcalyacac' },
          { nombre: 'Texcoco' },
          { nombre: 'Tezoyuca' },
          { nombre: 'Tianguistenco' },
          { nombre: 'Timilpan' },
          { nombre: 'Tlalmanalco' },
          { nombre: 'Tlalnepantla de Baz' },
          { nombre: 'Tlatlaya' },
          { nombre: 'Toluca' },
          { nombre: 'Tonanitla' },
          { nombre: 'Tonatico' },
          { nombre: 'Tultepec' },
          { nombre: 'Tultitlán' },
          { nombre: 'Valle de Bravo' },
          { nombre: 'Valle de Chalco Solidaridad' },
          { nombre: 'Villa de Allende' },
          { nombre: 'Villa del Carbón' },
          { nombre: 'Villa Guerrero' },
          { nombre: 'Villa Victoria' },
          { nombre: 'Xalatlaco' },
          { nombre: 'Xonacatlán' },
          { nombre: 'Zacazonapan' },
          { nombre: 'Zacualpan' },
          { nombre: 'Zinacantepec' },
          { nombre: 'Zumpahuacán' },
          { nombre: 'Zumpango' }
        ]
      }

      if (this.estado === 'Guanajuato') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Abasolo' },
          { nombre: 'Acámbaro' },
          { nombre: 'Apaseo el Alto' },
          { nombre: 'Apaseo el Grande' },
          { nombre: 'Atarjea' },
          { nombre: 'Celaya' },
          { nombre: 'Comonfort' },
          { nombre: 'Coroneo' },
          { nombre: 'Cortazar' },
          { nombre: 'Cuerámaro' },
          { nombre: 'Doctor Mora' },
          { nombre: 'Dolores Hidalgo' },
          { nombre: 'Guanajuato' },
          { nombre: 'Huanímaro' },
          { nombre: 'Irapuato' },
          { nombre: 'Jaral del Progreso' },
          { nombre: 'Jerécuaro' },
          { nombre: 'León' },
          { nombre: 'Manuel Doblado' },
          { nombre: 'Moroleón' },
          { nombre: 'Ocampo' },
          { nombre: 'Pénjamo' },
          { nombre: 'Pueblo Nuevo' },
          { nombre: 'Purísima del Rincón' },
          { nombre: 'Romita' },
          { nombre: 'Salamanca' },
          { nombre: 'Salvatierra' },
          { nombre: 'San Diego de la Unión' },
          { nombre: 'San Felipe' },
          { nombre: 'San Francisco del Rincón' },
          { nombre: 'San José Iturbide' },
          { nombre: 'San Luis de la Paz' },
          { nombre: 'San Miguel de Allende' },
          { nombre: 'Santa Catarina' },
          { nombre: 'Santa Cruz de Juventino' },
          { nombre: 'Santiago Maravatío' },
          { nombre: 'Silao' },
          { nombre: 'Tarandacuao' },
          { nombre: 'Tarimoro' },
          { nombre: 'Tierra Blanca' },
          { nombre: 'Uriangato' },
          { nombre: 'Valle de Santiago' },
          { nombre: 'Victoria' },
          { nombre: 'Villagrán' },
          { nombre: 'Xichú' },
          { nombre: 'Yuriria' }
        ]
      }

      if (this.estado === 'Guerrero') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acapulco de Juárez' },
          { nombre: 'Acatepec' },
          { nombre: 'Ahuacuotzingo' },
          { nombre: 'Ajuchitlán del Progreso' },
          { nombre: 'Alcozauca de Guerrero' },
          { nombre: 'Alpoyeca' },
          { nombre: 'Apaxtla de Castrejón' },
          { nombre: 'Arcelia' },
          { nombre: 'Atenango del Río' },
          { nombre: 'Atlamajalcingo del Monte' },
          { nombre: 'Atlixtac' },
          { nombre: 'Atoyac de Álvarez' },
          { nombre: 'Ayutla de los Libres' },
          { nombre: 'Azoyú' },
          { nombre: 'Benito Juárez' },
          { nombre: 'Buenavista de Cuéllar' },
          { nombre: 'Chilapa de Álvarez' },
          { nombre: 'Chilpancingo de los Bravo' },
          { nombre: 'Coahuayutla de José María Izazaga' },
          { nombre: 'Cochoapa el Grande' },
          { nombre: 'Cocula' },
          { nombre: 'Copala' },
          { nombre: 'Copalillo' },
          { nombre: 'Copanatoyac' },
          { nombre: 'Coyuca de Benítez' },
          { nombre: 'Coyuca de Catalán' },
          { nombre: 'Cuajinicuilapa' },
          { nombre: 'Cualác' },
          { nombre: 'Cuautepec' },
          { nombre: 'Cuetzala del Progreso' },
          { nombre: 'Cutzamala de Pinzón' },
          { nombre: 'Eduardo Neri' },
          { nombre: 'Florencio Villarreal' },
          { nombre: 'General Canuto A. Neri' },
          { nombre: 'General Heliodoro Castillo' },
          { nombre: 'Huamuxtitlán' },
          { nombre: 'Huitzuco de los Figueroa' },
          { nombre: 'Iguala de la Independencia' },
          { nombre: 'Igualapa' },
          { nombre: 'Iliatenco' },
          { nombre: 'Ixcateopan de Cuauhtémoc' },
          { nombre: 'José Joaquín de Herrera' },
          { nombre: 'Juan R. Escudero' },
          { nombre: 'Juchitán' },
          { nombre: 'La Unión de Isidoro Montes de Oca' },
          { nombre: 'Leonardo Bravo' },
          { nombre: 'Malinaltepec' },
          { nombre: 'Marquelia' },
          { nombre: 'Mártir de Cuilapan' },
          { nombre: 'Metlatónoc' },
          { nombre: 'Mochitlán' },
          { nombre: 'Olinalá' },
          { nombre: 'Ometepec' },
          { nombre: 'Pedro Ascencio Alquisiras' },
          { nombre: 'Petatlán' },
          { nombre: 'Pilcaya' },
          { nombre: 'Pungarabato' },
          { nombre: 'Quechultenango' },
          { nombre: 'San Luis Acatlán' },
          { nombre: 'San Marcos' },
          { nombre: 'San Miguel Totolapan' },
          { nombre: 'Taxco de Alarcón' },
          { nombre: 'Tecoanapa' },
          { nombre: 'Técpan de Galeana' },
          { nombre: 'Teloloapan' },
          { nombre: 'Tepecoacuilco de Trujano' },
          { nombre: 'Tetipac' },
          { nombre: 'Tixtla de Guerrero' },
          { nombre: 'Tlacoachistlahuaca' },
          { nombre: 'Tlacoapa' },
          { nombre: 'Tlalchapa' },
          { nombre: 'Tlalixtlaquilla de Maldanado' },
          { nombre: 'Tlapa de Comonfort' },
          { nombre: 'Tlapehuala' },
          { nombre: 'Xalpatláhuac' },
          { nombre: 'Xochihuehuetlán' },
          { nombre: 'Xochistlahuaca' },
          { nombre: 'Zapotitlán Tablas' },
          { nombre: 'Zihuatanejo de Azueta' },
          { nombre: 'Zirándaro de los Chávez' },
          { nombre: 'Zitlala' },

        ]
      }

      if (this.estado === 'Hidalgo') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acatlán' },
          { nombre: 'Acaxochitlán' },
          { nombre: 'Actopan' },
          { nombre: 'Agua Blanca de Iturbide' },
          { nombre: 'Ajacuba' },
          { nombre: 'Alfajayucan' },
          { nombre: 'Almoloya' },
          { nombre: 'Apan' },
          { nombre: 'Atitalaquia' },
          { nombre: 'Atlapexco' },
          { nombre: 'Atotonilco de Tula' },
          { nombre: 'Atotonilco el Grande' },
          { nombre: 'Calnali' },
          { nombre: 'Chapantongo' },
          { nombre: 'Chapulhuacán' },
          { nombre: 'Cardonal' },
          { nombre: 'Chilcuautla' },
          { nombre: 'Cuautepec de Hinojosa' },
          { nombre: 'El Arenal' },
          { nombre: 'Eloxochitlán' },
          { nombre: 'Emiliano Zapata' },
          { nombre: 'Epazoyucan' },
          { nombre: 'Francisco I. Madero' },
          { nombre: 'Huasca de Ocampo' },
          { nombre: 'Huautla' },
          { nombre: 'Huazalingo' },
          { nombre: 'Huejutla de Reyes' },
          { nombre: 'Huehuetla' },
          { nombre: 'Huichapan' },
          { nombre: 'Ixmiquilpan' },
          { nombre: 'Jacala de Ledezma' },
          { nombre: 'Jaltocán' },
          { nombre: 'Juárez Hidalgo' },
          { nombre: 'La Misión' },
          { nombre: 'Lolotla' },
          { nombre: 'Metepec' },
          { nombre: 'Metztitlán' },
          { nombre: 'Mineral de la Reforma' },
          { nombre: 'Mineral del Chico' },
          { nombre: 'Mineral del Monte' },
          { nombre: 'Mixquiahuala de Juárez' },
          { nombre: 'Molango de Escamilla' },
          { nombre: 'Nicolás Flores' },
          { nombre: 'Nopala de Villagrán' },
          { nombre: 'Omitlán de Juárez' },
          { nombre: 'Pachuca de Soto' },
          { nombre: 'Pacula' },
          { nombre: 'Pisaflores' },
          { nombre: 'Progreso de Obregón' },
          { nombre: 'San Agustín Metzquititlán' },
          { nombre: 'San Agustín Tlaxiaca' },
          { nombre: 'San Bartolo Tutotepec' },
          { nombre: 'San Felipe Orizatlán' },
          { nombre: 'San Salvador' },
          { nombre: 'Santiago de Anaya' },
          { nombre: 'Santiago Tulantepec de Lugo Guerrero' },
          { nombre: 'Singuilucan' },
          { nombre: 'Tasquillo' },
          { nombre: 'Tecozautla' },
          { nombre: 'Tenango de Doria' },
          { nombre: 'Tepeapulco' },
          { nombre: 'Tepehuacán de Guerrero' },
          { nombre: 'Tepeji del Río de Ocampo' },
          { nombre: 'Tepetitlán' },
          { nombre: 'Tetepango' },
          { nombre: 'Tezontepec de Aldama' },
          { nombre: 'Tianguistengo' },
          { nombre: 'Tizayuca' },
          { nombre: 'Tlahuelilpan' },
          { nombre: 'Tlahuiltepa' },
          { nombre: 'Tlanalapa' },
          { nombre: 'Tlanchinol' },
          { nombre: 'Tlaxcoapan' },
          { nombre: 'Tolcayuca' },
          { nombre: 'Tula de Allende' },
          { nombre: 'Tulancingo de Bravo' },
          { nombre: 'Villa de Tezontepec' },
          { nombre: 'Xochiatipan' },
          { nombre: 'Xochicoatlán' },
          { nombre: 'Yahualica' },
          { nombre: 'Zacualtipán de Ángeles' },
          { nombre: 'Zapotlán de Juárez' },
          { nombre: 'Zempoala' },
          { nombre: 'Zimapán' }
        ]
      }

      if (this.estado === 'Jalisco') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acatic' },
          { nombre: 'Acatlán de Juárez' },
          { nombre: 'Ahualulco de Mercado' },
          { nombre: 'Amacueca' },
          { nombre: 'Amatitán' },
          { nombre: 'Ameca' },
          { nombre: 'Arandas' },
          { nombre: 'Atemajac de Brizuela' },
          { nombre: 'Atengo' },
          { nombre: 'Atenguillo' },
          { nombre: 'Atotonilco el Alto' },
          { nombre: 'Atoyac' },
          { nombre: 'Autlán de Navarro' },
          { nombre: 'Ayotlán' },
          { nombre: 'Ayutla' },
          { nombre: 'Bolaños' },
          { nombre: 'Cabo Corrientes' },
          { nombre: 'Cañadas de Obregón' },
          { nombre: 'Casimiro Castillo' },
          { nombre: 'Chapala' },
          { nombre: 'Chimaltitán' },
          { nombre: 'Chiquilistlán' },
          { nombre: 'Cihuatlán' },
          { nombre: 'Cocula' },
          { nombre: 'Colotlán' },
          { nombre: 'Concepción de Buenos Aires' },
          { nombre: 'Cuauitlán de García Barragán' },
          { nombre: 'Cuautla' },
          { nombre: 'Cuquío' },
          { nombre: 'Degollado' },
          { nombre: 'Ejutla' },
          { nombre: 'El Arenal' },
          { nombre: 'El Grullo' },
          { nombre: 'El Limón' },
          { nombre: 'El Salto' },
          { nombre: 'Encarnación de Díaz' },
          { nombre: 'Etzatlán' },
          { nombre: 'Gómez Farías' },
          { nombre: 'Guachinango' },
          { nombre: 'Guadalajara' },
          { nombre: 'Hostotipaquillo' },
          { nombre: 'Huejúcar' },
          { nombre: 'Huejuquilla el Alto' },
          { nombre: 'Ixtlahuacán de los Membrillos' },
          { nombre: 'Ixtlahuacán del Río' },
          { nombre: 'Jalostotitlán' },
          { nombre: 'Jamay' },
          { nombre: 'Jesús María' },
          { nombre: 'Jilotlán de los Dolores' },
          { nombre: 'Jocotepec' },
          { nombre: 'Juanacatlán' },
          { nombre: 'Juchitlán' },
          { nombre: 'La Barca' },
          { nombre: 'Lagos de Moreno' },
          { nombre: 'La Manzanilla de la Paz' },
          { nombre: 'La Huerta' },
          { nombre: 'Magdalena' },
          { nombre: 'Mascota' },
          { nombre: 'Mazamitla' },
          { nombre: 'Mexticacán' },
          { nombre: 'Mezquitic' },
          { nombre: 'Mixtlán' },
          { nombre: 'Ojuelos de Jalisco' },
          { nombre: 'Ocotlán' },
          { nombre: 'Pihuamo' },
          { nombre: 'Poncitlán' },
          { nombre: 'Puerto Vallarta' },
          { nombre: 'Quitupan' },
          { nombre: 'San Cristóbal de la Barranca' },
          { nombre: 'San Diego de Alejandría' },
          { nombre: 'San Gabriel' },
          { nombre: 'San Ignacio Cerro Gordo' },
          { nombre: 'San Juan de los Lagos' },
          { nombre: 'San Juanito de Escobedo' },
          { nombre: 'San Julián' },
          { nombre: 'San Marcos' },
          { nombre: 'San Martín de Bolaños' },
          { nombre: 'San Martín Hidalgo' },
          { nombre: 'San Miguel el Alto' },
          { nombre: 'San Sebastián del Oeste' },
          { nombre: 'Santa María de los Ángeles' },
          { nombre: 'Santa María del Oro' },
          { nombre: 'Sayula' },
          { nombre: 'Tala' },
          { nombre: 'Talpa de Allende' },
          { nombre: 'Tamazula de Gordiano' },
          { nombre: 'Tapalpa' },
          { nombre: 'Tecalitlán' },
          { nombre: 'Techaluta de Montenegro' },
          { nombre: 'Tecolotlán' },
          { nombre: 'Tenamaxtlán' },
          { nombre: 'Teocaltiche' },
          { nombre: 'Teocuitatlán de Corona' },
          { nombre: 'Tepatitlán de Morelos' },
          { nombre: 'Tequila' },
          { nombre: 'Teuchitlán' },
          { nombre: 'Tizapán el Alto' },
          { nombre: 'Tlajomulco de Zúñiga' },
          { nombre: 'Tlaquepaque' },
          { nombre: 'Tolimán' },
          { nombre: 'Tomatlán' },
          { nombre: 'Tonalá' },
          { nombre: 'Tonaya' },
          { nombre: 'Tonila' },
          { nombre: 'Totatiche' },
          { nombre: 'Tototlán' },
          { nombre: 'Tuxcacuesco' },
          { nombre: 'Tuxcueca' },
          { nombre: 'Tuxpan' },
          { nombre: 'Unión de San Antonio' },
          { nombre: 'Unión de Tula' },
          { nombre: 'Valle de Guadalupe' },
          { nombre: 'Valle de Juárez' },
          { nombre: 'Villa Corona' },
          { nombre: 'Villa Guerrero' },
          { nombre: 'Villa Hidalgo' },
          { nombre: 'Villa Purificación' },
          { nombre: 'Yahualica de González Gallo' },
          { nombre: 'Zacoalco de Torres' },
          { nombre: 'Zapopan' },
          { nombre: 'Zapotiltic' },
          { nombre: 'Zapotitlán de Vadillo' },
          { nombre: 'Zapotlán del Rey' },
          { nombre: 'Zapotlanejo' },
          { nombre: 'Zapotlán el Grande' }
        ]
      }

      if (this.estado === 'Michoacán') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acuitzio' },
          { nombre: 'Aguililla' },
          { nombre: 'Álvaro Obregón' },
          { nombre: 'Angamacutiro' },
          { nombre: 'Angangueo' },
          { nombre: 'Apatzingán' },
          { nombre: 'Aporo' },
          { nombre: 'Aquila' },
          { nombre: 'Ario de Rosales' },
          { nombre: 'Arteaga Riseñas' },
          { nombre: 'Briseñas' },
          { nombre: 'Buenavista' },
          { nombre: 'Carácuaro' },
          { nombre: 'Charapan' },
          { nombre: 'Charo' },
          { nombre: 'Chavinda' },
          { nombre: 'Cherán' },
          { nombre: 'Chilchota' },
          { nombre: 'Chuinicuila' },
          { nombre: 'Chucándiro' },
          { nombre: 'Churintzio' },
          { nombre: 'Churumuco' },
          { nombre: 'Coahuayana' },
          { nombre: 'Coalcomán de Vázquez Pallares' },
          { nombre: 'Coeneo' },
          { nombre: 'Cojumatlán de Régules' },
          { nombre: 'Contepec' },
          { nombre: 'Copándaro' },
          { nombre: 'Cotija' },
          { nombre: 'Cuitzeo' },
          { nombre: 'Escuandureo' },
          { nombre: 'Epitacio Huerta' },
          { nombre: 'Erongarícuaro' },
          { nombre: 'Gabriel Zamora' },
          { nombre: 'Hidalgo' },
          { nombre: 'Huandacareo' },
          { nombre: 'Huaniqueo' },
          { nombre: 'Huetamo' },
          { nombre: 'Huiramba' },
          { nombre: 'Indaparapeo' },
          { nombre: 'Irimbo' },
          { nombre: 'Ixtlán' },
          { nombre: 'Jacona' },
          { nombre: 'Jiménez' },
          { nombre: 'Jiquilpan' },
          { nombre: 'José Sixto Verduzco' },
          { nombre: 'Juárez' },
          { nombre: 'Jungapeo' },
          { nombre: 'La Huacana' },
          { nombre: 'La Piedad' },
          { nombre: 'Lagunillas' },
          { nombre: 'Lázaro Cárdenas' },
          { nombre: 'Los Reyes' },
          { nombre: 'Madero' },
          { nombre: 'Maravatío' },
          { nombre: 'Marcos Castellanos' },
          { nombre: 'Morelia' },
          { nombre: 'Morelos' },
          { nombre: 'Múgica' },
          { nombre: 'Nahuatzen' },
          { nombre: 'Nocupétaro' },
          { nombre: 'Nuevo Parangaricutiro' },
          { nombre: 'Nuevo Urecho' },
          { nombre: 'Numarán' },
          { nombre: 'Ocampo' },
          { nombre: 'Pajacuarán' },
          { nombre: 'Panindícuaro' },
          { nombre: 'Paracho' },
          { nombre: 'Parácuaro' },
          { nombre: 'Pátzcuaro' },
          { nombre: 'Penjamillo' },
          { nombre: 'Peribán' },
          { nombre: 'Purépero' },
          { nombre: 'Puruándiro' },
          { nombre: 'Queréndaro' },
          { nombre: 'Quiroga' },
          { nombre: 'Sahuayo' },
          { nombre: 'Salvador Escalante' },
          { nombre: 'San Lucas' },
          { nombre: 'Santa Ana Maya' },
          { nombre: 'Senguio' },
          { nombre: 'Susupuato' },
          { nombre: 'Tancítaro' },
          { nombre: 'Tangamandapio' },
          { nombre: 'Tangancícuaro' },
          { nombre: 'Tanhuato' },
          { nombre: 'Taretan' },
          { nombre: 'Tarímbaro' },
          { nombre: 'Tepalcatepec' },
          { nombre: 'Tingüindín' },
          { nombre: 'Tingambato' },
          { nombre: 'Tiquicheo de Nicolás Romero' },
          { nombre: 'Tlalpujahua' },
          { nombre: 'Tlazazalca' },
          { nombre: 'Tocumbo' },
          { nombre: 'Tumbiscatío' },
          { nombre: 'Turicato' },
          { nombre: 'Tuxpan' },
          { nombre: 'Tuzantla' },
          { nombre: 'Tzintzuntzan' },
          { nombre: 'Tzitzio' },
          { nombre: 'Uruapan' },
          { nombre: 'Venustiano Carranza' },
          { nombre: 'Villamar' },
          { nombre: 'Vista Hermosa' },
          { nombre: 'Yurécuaro' },
          { nombre: 'Zacapu' },
          { nombre: 'Zamora' },
          { nombre: 'Zináparo' },
          { nombre: 'Zinapécuaro' },
          { nombre: 'Ziracuaretiro' },
          { nombre: 'Zitácuaro' },
        ]
      }

      if (this.estado === 'Morelos') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Amacuzac' },
          { nombre: 'Atlatlahucan' },
          { nombre: 'Axochiapan' },
          { nombre: 'Ayala' },
          { nombre: 'Coatlán del Río' },
          { nombre: 'Cuautla' },
          { nombre: 'Cuernavaca' },
          { nombre: 'Emiliano Zapata' },
          { nombre: 'Huitzilac' },
          { nombre: 'Jantetelco' },
          { nombre: 'Jiutepec' },
          { nombre: 'Jojutla' },
          { nombre: 'Jonacatepec' },
          { nombre: 'Mazatepec' },
          { nombre: 'Miacatlán' },
          { nombre: 'Ocuituco' },
          { nombre: 'Puente de Ixtla' },
          { nombre: 'Temixco' },
          { nombre: 'Temoac' },
          { nombre: 'Tepalcingo' },
          { nombre: 'Tepoztlán' },
          { nombre: 'Tetecala' },
          { nombre: 'Tetela del Volcán' },
          { nombre: 'Tlalnepantla' },
          { nombre: 'Tlaltizapán de Zapata' },
          { nombre: 'Tlaquiltenango' },
          { nombre: 'Tlayacapan' },
          { nombre: 'Totolapan' },
          { nombre: 'Xochitepec' },
          { nombre: 'Yautepec de Zaragoza' },
          { nombre: 'Yecapixtla' },
          { nombre: 'Zacatepec de Hidalgo' },
          { nombre: 'Zacualpan de Amilpas' },
        ]
      }

      if (this.estado === 'Nayarit') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acaponeta' },
          { nombre: 'Ahuacatlán' },
          { nombre: 'Amatlán de Cañas' },
          { nombre: 'Bahía de Banderas' },
          { nombre: 'Compostela' },
          { nombre: 'El Nayar' },
          { nombre: 'Huajicori' },
          { nombre: 'Ixtlán del Río' },
          { nombre: 'Jala' },
          { nombre: 'La Yesca' },
          { nombre: 'Rosamorada' },
          { nombre: 'Ruíz' },
          { nombre: 'San Blas' },
          { nombre: 'San Pedro Lagunillas' },
          { nombre: 'Santa María del Oro' },
          { nombre: 'Santiago Ixcuintla' },
          { nombre: 'Tecuala' },
          { nombre: 'Tepic' },
          { nombre: 'Tuxpan' },
          { nombre: 'Xalisco' },
        ]
      }

      if (this.estado === 'Nuevo León') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Abasolo' },
          { nombre: 'Agualeguas' },
          { nombre: 'Allende' },
          { nombre: 'Anáhuac' },
          { nombre: 'Apodaca' },
          { nombre: 'Aramberri' },
          { nombre: 'Bustamante' },
          { nombre: 'Cadereyta Jiménez' },
          { nombre: 'Cerralvo' },
          { nombre: 'China' },
          { nombre: 'Ciénega de Flores' },
          { nombre: 'Doctor Arroyo' },
          { nombre: 'Doctor Coss' },
          { nombre: 'Doctor González' },
          { nombre: 'El Carmen' },
          { nombre: 'Galeana' },
          { nombre: 'García' },
          { nombre: 'General Bravo' },
          { nombre: 'General Escobedo' },
          { nombre: 'General Terán' },
          { nombre: 'General Treviño' },
          { nombre: 'General Zaragoza' },
          { nombre: 'General Zuazua' },
          { nombre: 'Guadalupe' },
          { nombre: 'Hidalgo' },
          { nombre: 'Higueras' },
          { nombre: 'Hualahuises' },
          { nombre: 'Iturbide' },
          { nombre: 'Juárez' },
          { nombre: 'Lampazos de Naranjo' },
          { nombre: 'Linares' },
          { nombre: 'Los Aldamas' },
          { nombre: 'Los Herreras' },
          { nombre: 'Los Ramones' },
          { nombre: 'Marín' },
          { nombre: 'Melchor Ocampo' },
          { nombre: 'Mier y Noriega' },
          { nombre: 'Mina' },
          { nombre: 'Montemorelos' },
          { nombre: 'Monterrey' },
          { nombre: 'Parás' },
          { nombre: 'Pesquería' },
          { nombre: 'Rayones' },
          { nombre: 'Sabinas Hidalgo' },
          { nombre: 'Salinas Victoria' },
          { nombre: 'San Nicolás de los Garza' },
          { nombre: 'San Pedro Garza García' },
          { nombre: 'Santa Catarina' },
          { nombre: 'Santiago' },
          { nombre: 'Vallecillo' },
          { nombre: 'Villaldama' },
        ]
      }

      if (this.estado === 'Oaxaca') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Abejones' },
          { nombre: 'Acatlán de Pérez Figueroa' },
          { nombre: 'Ánimas Trujano' },
          { nombre: 'Asunción Cacalotepec' },
          { nombre: 'Asunción Cuyotepeji' },
          { nombre: 'Asunción Ixtaltepec' },
          { nombre: 'Asunción Nochixtlán' },
          { nombre: 'Asunción Ocotlán' },
          { nombre: 'Asunción Tlacolulita' },
          { nombre: 'Ayoquezco de Aldama' },
          { nombre: 'Ayotzintepec' },
          { nombre: 'Calihualá' },
          { nombre: 'Candelaria Loxicha' },
          { nombre: 'Capulalpam de Méndez' },
          { nombre: 'Chahuites' },
          { nombre: 'Chalcatongo de Hidalgo' },
          { nombre: 'Chiquihuitlán de Benito Juárez' },
          { nombre: 'Ciénega de Zimatlán' },
          { nombre: 'Ciudad Ixtepec' },
          { nombre: 'Coatecas Altas' },
          { nombre: 'Coicoyán de las Flores' },
          { nombre: 'Concepción Buenavista' },
          { nombre: 'Concepción Pápalo' },
          { nombre: 'Constancia del Rosario' },
          { nombre: 'Cosolapa' },
          { nombre: 'Cosoltepec' },
          { nombre: 'Cuilapam de Guerrero' },
          { nombre: 'Cuyamecalco Villa de Zaragoza' },
          { nombre: 'El Barrio de la Soledad' },
          { nombre: 'El Espinal' },
          { nombre: 'Eloxochitlán de Flores Magón' },
          { nombre: 'Fresnillo de Trujano' },
          { nombre: 'Guadalupe de Ramírez' },
          { nombre: 'Guadalupe Etla' },
          { nombre: 'Guelatao de Juárez' },
          { nombre: 'Guevea de Humboldt' },
          { nombre: 'Heróica Ciudad de Ejutla de Crespo' },
          { nombre: 'Heróica Ciudad de Huajuapan de León' },
          { nombre: 'Heróica Ciudad de Tlaxiaco' },
          { nombre: 'Huautepec' },
          { nombre: 'Huautla de Jiménez' },
          { nombre: 'Ixpantepec Nieves' },
          { nombre: 'Ixtlán de Juárez' },
          { nombre: 'Juchitán de Zaragoza' },
          { nombre: 'La Compañía' },
          { nombre: 'La Pe' },
          { nombre: 'La Reforma' },
          { nombre: 'La Trinidad Vista Hermosa' },
          { nombre: 'Loma Bonita' },
          { nombre: 'Magdalena Apasco' },
          { nombre: 'Magdalena Jaltepec' },
          { nombre: 'Magdalena Mixtepec' },
          { nombre: 'Magdalena Ocotlán' },
          { nombre: 'Magdalena Peñasco' },
          { nombre: 'Magdalena Teitipac' },
          { nombre: 'Magdalena Tequisistlán' },
          { nombre: 'Magdalena Tlacotepec' },
          { nombre: 'Magdalena Yodocono de Porfirio Díaz' },
          { nombre: 'Magdalena Zahuatlán' },
          { nombre: 'Mariscala de Juárez' },
          { nombre: 'Mártires de Tacubaya' },
          { nombre: 'Matías Romero Avendaño' },
          { nombre: 'Mazatlán Villa de Flores' },
          { nombre: 'Mesones Hidalgo' },
          { nombre: 'Miahuatlán de Porfirio Díaz' },
          { nombre: 'Mixistlán de la Reforma' },
          { nombre: 'Monjas' },
          { nombre: 'Natividad' },
          { nombre: 'Nazareno Etla' },
          { nombre: 'Nejapa de Madero' },
          { nombre: 'Nuevo Zoquiapam' },
          { nombre: 'Oaxaca de Juárez' },
          { nombre: 'Ocotlán de Morelos' },
          { nombre: 'Pinotepa de Don Luis' },
          { nombre: 'Pluma Hidalgo' },
          { nombre: 'Putla Villa de Guerrero' },
          { nombre: 'Reforma de Pineda' },
          { nombre: 'Reyes Etla' },
          { nombre: 'Rojas de Cuauhtémoc' },
          { nombre: 'Salina Cruz' },
          { nombre: 'San Agustín Amatengo' },
          { nombre: 'San Agustín Atenango' },
          { nombre: 'San Agustín Chayuco' },
          { nombre: 'San Agustín de las Juntas' },
          { nombre: 'San Agustín Etla' },
          { nombre: 'San Agustín Loxicha' },
          { nombre: 'San Agustín Tlacotepec' },
          { nombre: 'San Agustín Yatareni' },
          { nombre: 'San Andrés Cabecera Nueva' },
          { nombre: 'San Andrés Dinicuiti' },
          { nombre: 'San Andrés Huaxpaltepec' },
          { nombre: 'San Andrés Huayapam' },
          { nombre: 'San Andrés Ixtlahuaca' },
          { nombre: 'San Andrés Lagunas' },
          { nombre: 'San Andrés Nuxiño' },
          { nombre: 'San Andrés Paxtlán' },
          { nombre: 'San Andrés Sinaxtla' },
          { nombre: 'San Andrés Solaga' },
          { nombre: 'San Andrés Teotilalpam' },
          { nombre: 'San Andrés Tepetlapa' },
          { nombre: 'San Andrés Yaa' },
          { nombre: 'San Andrés Zabache' },
          { nombre: 'San Andrés Zautla' },
          { nombre: 'San Antonino Castillo Velasco' },
          { nombre: 'San Antonino el Alto' },
          { nombre: 'San Antonino Monteverde' },
          { nombre: 'San Antonio Acutla' },
          { nombre: 'San Antonio de la Cal' },
          { nombre: 'San Antonio Huitepec' },
          { nombre: 'San Antonio Nanahuatipam' },
          { nombre: 'San Antonio Sinicahua' },
          { nombre: 'San Antonio Tepetlapa' },
          { nombre: 'San Baltazar Chichicápam' },
          { nombre: 'San Baltazar Loxicha' },
          { nombre: 'San Baltazar Yatzachi el Bajo' },
          { nombre: 'San Bartolo Coyotepec' },
          { nombre: 'San Bartolo Soyaltepec' },
          { nombre: 'San Bartolo Yautepec' },
          { nombre: 'San Bartolomé Ayautla' },
          { nombre: 'San Bartolomé Loxicha' },
          { nombre: 'San Bartolomé Quialana' },
          { nombre: 'San Bartolomé Yucuañe' },
          { nombre: 'San Bartolomé Zoogocho' },
          { nombre: 'San Bernardo Mixtepec' },
          { nombre: 'San Blas Atempa' },
          { nombre: 'San Carlos Yautepec' },
          { nombre: 'San Cristóbal Amatlán' },
          { nombre: 'San Cristóbal Amoltepec' },
          { nombre: 'San Cristóbal Lachirioag' },
          { nombre: 'San Cristóbal Suchixtlahuaca' },
          { nombre: 'San Dionisio del Mar' },
          { nombre: 'San Dionisio Ocotepec' },
          { nombre: 'San Dionisio Ocotlán' },
          { nombre: 'San Esteban Atatlahuca' },
          { nombre: 'San Felipe Jalapa de Díaz' },
          { nombre: 'San Felipe Tejalapam' },
          { nombre: 'San Felipe Usila' },
          { nombre: 'San Francisco Cahuacuá' },
          { nombre: 'San Francisco Cajonos' },
          { nombre: 'San Francisco Chapulapa' },
          { nombre: 'San Francisco Chindua' },
          { nombre: 'San Francisco del Mar' },
          { nombre: 'San Francisco Huehuetlán' },
          { nombre: 'San Francisco Ixhuatán' },
          { nombre: 'San Francisco Jaltepetongo' },
          { nombre: 'San Francisco Lachigoló' },
          { nombre: 'San Francisco Logueche' },
          { nombre: 'San Francisco Nuxaño' },
          { nombre: 'San Francisco Ozolotepec' },
          { nombre: 'San Francisco Sola' },
          { nombre: 'San Francisco Telixtlahuaca' },
          { nombre: 'San Francisco Teopan' },
          { nombre: 'San Francisco Tlapancingo' },
          { nombre: 'San Gabriel Mixtepec' },
          { nombre: 'San Ildefonso Amatlán' },
          { nombre: 'San Ildefonso Sola' },
          { nombre: 'San Ildefonso Villa Alta' },
          { nombre: 'San Jacinto Amilpas' },
          { nombre: 'San Jacinto Tlacotepec' },
          { nombre: 'San Jerónimo Coatlán' },
          { nombre: 'San Jerónimo Silacayoapilla' },
          { nombre: 'San Jerónimo Sosola' },
          { nombre: 'San Jerónimo Taviche' },
          { nombre: 'San Jerónimo Tecoatl' },
          { nombre: 'San Jerónimo Tlacochahuaya' },
          { nombre: 'San Jorge Nuchita' },
          { nombre: 'San José Ayuquila' },
          { nombre: 'San José Chiltepec' },
          { nombre: 'San José del Peñasco' },
          { nombre: 'San José del Progreso' },
          { nombre: 'San José Estancia Grande' },
          { nombre: 'San José Independencia' },
          { nombre: 'San José Lachiguiri' },
          { nombre: 'San José Tenango' },
          { nombre: 'San Juan Achiutla' },
          { nombre: 'San Juan Atepec' },
          { nombre: 'San Juan Bautista Atatlahuca' },
          { nombre: 'San Juan Bautista Coixtlahuaca' },
          { nombre: 'San Juan Bautista Cuicatlán' },
          { nombre: 'San Juan Bautista Guelache' },
          { nombre: 'San Juan Bautista Jayacatlán' },
          { nombre: 'San Juan Bautista Lo de Soto' },
          { nombre: 'San Juan Bautista Suchitepec' },
          { nombre: 'San Juan Bautista Tlacoatzintepec' },
          { nombre: 'San Juan Bautista Tlachichilco' },
          { nombre: 'San Juan Bautista Tuxtepec' },
          { nombre: 'San Juan Bautista Valle Nacional' },
          { nombre: 'San Juan Cacahuatepec' },
          { nombre: 'San Juan Chicomezúchil' },
          { nombre: 'San Juan Chilateca' },
          { nombre: 'San Juan Cieneguilla' },
          { nombre: 'San Juan Coatzóspam' },
          { nombre: 'San Juan Colorado' },
          { nombre: 'San Juan Comaltepec' },
          { nombre: 'San Juan Cotzocón' },
          { nombre: 'San Juan del Estado' },
          { nombre: 'San Juan de los Cués' },
          { nombre: 'San Juan del Río' },
          { nombre: 'San Juan Diuxi' },
          { nombre: 'San Juan Evangelista Analco' },
          { nombre: 'San Juan Guelavia' },
          { nombre: 'San Juan Guichicovi' },
          { nombre: 'San Juan Ihualtepec' },
          { nombre: 'San Juan Juquila Mixes' },
          { nombre: 'San Juan Juquila Vijanos' },
          { nombre: 'San Juan Lachao' },
          { nombre: 'San Juan Lachigalla' },
          { nombre: 'San Juan Lajarcia' },
          { nombre: 'San Juan Lalana' },
          { nombre: 'San Juan Mazatlán' },
          { nombre: 'San Juan Mixtepec, distrito 08' },
          { nombre: 'San Juan Mixtepec, distrito 26' },
          { nombre: 'San Juan Ñumi' },
          { nombre: 'San Juan Ozolotepec' },
          { nombre: 'San Juan Petlapa' },
          { nombre: 'San Juan Quiahije' },
          { nombre: 'San Juan Quiotepec' },
          { nombre: 'San Juan Sayultepec' },
          { nombre: 'San Juan Tabaá' },
          { nombre: 'San Juan Tamazola' },
          { nombre: 'San Juan Teita' },
          { nombre: 'San Juan Teitipac' },
          { nombre: 'San Juan Tepeuxila' },
          { nombre: 'San Juan Teposcolula' },
          { nombre: 'San Juan Yaeé' },
          { nombre: 'San Juan Yatzona' },
          { nombre: 'San Juan Yucuita' },
          { nombre: 'San Lorenzo' },
          { nombre: 'San Lorenzo Albarradas' },
          { nombre: 'San Lorenzo Cacaotepec' },
          { nombre: 'San Lorenzo Cuaunecuiltitla' },
          { nombre: 'San Lorenzo Texmelucan' },
          { nombre: 'San Lorenzo Victoria' },
          { nombre: 'San Lucas Camotlán' },
          { nombre: 'San Lucas Ojitlán' },
          { nombre: 'San Lucas Quiaviní' },
          { nombre: 'San Lucas Zoquiápam' },
          { nombre: 'San Luis Amatlán' },
          { nombre: 'San Marcial Ozolotepec' },
          { nombre: 'San Marcos Arteaga' },
          { nombre: 'San Martín de los Cansecos' },
          { nombre: 'San Martín Huamelúlpam' },
          { nombre: 'San Martín Itunyoso' },
          { nombre: 'San Martín Lachilá' },
          { nombre: 'San Martín Peras' },
          { nombre: 'San Martín Tilcajete' },
          { nombre: 'San Martín Toxpalan' },
          { nombre: 'San Martín Zacatepec' },
          { nombre: 'San Mateo Cajonos' },
          { nombre: 'San Mateo del Mar' },
          { nombre: 'San Mateo Etlatongo' },
          { nombre: 'San Mateo Nejápam' },
          { nombre: 'San Mateo Peñasco' },
          { nombre: 'San Mateo Piñas' },
          { nombre: 'San Mateo Río Hondo' },
          { nombre: 'San Mateo Sindihui' },
          { nombre: 'San Mateo Tlapiltepec' },
          { nombre: 'San Mateo Yoloxochitlán' },
          { nombre: 'San Melchor Betaza' },
          { nombre: 'San Miguel Achiutla' },
          { nombre: 'San Miguel Ahuehuetitlán' },
          { nombre: 'San Miguel Aloápam' },
          { nombre: 'San Miguel Amatitlán' },
          { nombre: 'San Miguel Amatlán' },
          { nombre: 'San Miguel Coatlán' },
          { nombre: 'San Miguel Chicahua' },
          { nombre: 'San Miguel Chimalapa' },
          { nombre: 'San Miguel del Puerto' },
          { nombre: 'San Miguel del Río' },
          { nombre: 'San Miguel Ejutla' },
          { nombre: 'San Miguel el Grande' },
          { nombre: 'San Miguel Huautla' },
          { nombre: 'San Miguel Mixtepec' },
          { nombre: 'San Miguel Panixtlahuaca' },
          { nombre: 'San Miguel Peras' },
          { nombre: 'San Miguel Piedras' },
          { nombre: 'San Miguel Quetzaltepec' },
          { nombre: 'San Miguel Santa Flor' },
          { nombre: 'San Miguel Soyaltepec' },
          { nombre: 'San Miguel Suchixtepec' },
          { nombre: 'San Miguel Tecomatlán' },
          { nombre: 'San Miguel Tenango' },
          { nombre: 'San Miguel Tequixtepec' },
          { nombre: 'San Miguel Tilquiápam' },
          { nombre: 'San Miguel Tlacamama' },
          { nombre: 'San Miguel Tlacotepec' },
          { nombre: 'San Miguel Tulancingo' },
          { nombre: 'San Miguel Yotao' },
          { nombre: 'San Nicolás' },
          { nombre: 'San Nicolás Hidalgo' },
          { nombre: 'San Pablo Coatlán' },
          { nombre: 'San Pablo Cuatro Venados' },
          { nombre: 'San Pablo Etla' },
          { nombre: 'San Pablo Huitzo' },
          { nombre: 'San Pablo Huixtepec' },
          { nombre: 'San Pablo Macuiltianguis' },
          { nombre: 'San Pablo Tijaltepec' },
          { nombre: 'San Pablo Villa de Mitla' },
          { nombre: 'San Pablo Yaganiza' },
          { nombre: 'San Pedro Amuzgos' },
          { nombre: 'San Pedro Apóstol' },
          { nombre: 'San Pedro Atoyac' },
          { nombre: 'San Pedro Cajonos' },
          { nombre: 'San Pedro Comitancillo' },
          { nombre: 'San Pedro Cocaltepec Cántaros' },
          { nombre: 'San Pedro el Alto' },
          { nombre: 'San Pedro Huamelula' },
          { nombre: 'San Pedro Huilotepec' },
          { nombre: 'San Pedro Ixcatlán' },
          { nombre: 'San Pedro Ixtlahuaca' },
          { nombre: 'San Pedro Jaltepetongo' },
          { nombre: 'San Pedro Jicayán' },
          { nombre: 'San Pedro Jocotipac' },
          { nombre: 'San Pedro Juchatengo' },
          { nombre: 'San Pedro Mártir' },
          { nombre: 'San Pedro Mártir Quiechapa' },
          { nombre: 'San Pedro Mártir Yucuxaco' },
          { nombre: 'San Pedro Mixtepec, distrito 22' },
          { nombre: 'San Pedro Mixtepec, distrito 26' },
          { nombre: 'San Pedro Molinos' },
          { nombre: 'San Pedro Nopala' },
          { nombre: 'San Pedro Ocopetatillo' },
          { nombre: 'San Pedro Ocotepec' },
          { nombre: 'San Pedro Pochutla' },
          { nombre: 'San Pedro Quiatoni' },
          { nombre: 'San Pedro Sochiápam' },
          { nombre: 'San Pedro Tapanatepec' },
          { nombre: 'San Pedro Taviche' },
          { nombre: 'San Pedro Teozacoalco' },
          { nombre: 'San Pedro Teutila' },
          { nombre: 'San Pedro Tidaá' },
          { nombre: 'San Pedro Topiltepec' },
          { nombre: 'San Pedro Totolápam' },
          { nombre: 'San Pedro y San Pablo Ayutla' },
          { nombre: 'San Pedro y San Pablo Teposcolula' },
          { nombre: 'San Pedro y San Pablo Tequixtepec' },
          { nombre: 'San Pedro Yaneri' },
          { nombre: 'San Pedro Yólox' },
          { nombre: 'San Pedro Yucunama' },
          { nombre: 'San Raymundo Jalpan' },
          { nombre: 'San Sebastián Abasolo' },
          { nombre: 'San Sebastián Coatlán' },
          { nombre: 'San Sebastián Ixcapa' },
          { nombre: 'San Sebastián Nicananduta' },
          { nombre: 'San Sebastián Río Hondo' },
          { nombre: 'San Sebastián Tecomaxtlahuaca' },
          { nombre: 'San Sebastián Teitipac' },
          { nombre: 'San Sebastián Tutla' },
          { nombre: 'San Simón Almolongas' },
          { nombre: 'San Simón Zahuatlán' },
          { nombre: 'Santa Ana' },
          { nombre: 'Santa Ana Ateixtlahuaca' },
          { nombre: 'Santa Ana Cuauhtémoc' },
          { nombre: 'Santa Ana del Valle' },
          { nombre: 'Santa Ana Tavela' },
          { nombre: 'Santa Ana Tlapacoyan' },
          { nombre: 'Santa Ana Yareni' },
          { nombre: 'Santa Ana Zegache' },
          { nombre: 'Santa Catalina Quieri' },
          { nombre: 'Santa Catarina Cuixtla' },
          { nombre: 'Santa Catarina Ixtepeji' },
          { nombre: 'Santa Catarina Juquila' },
          { nombre: 'Santa Catarina Lachatao' },
          { nombre: 'Santa Catarina Loxicha' },
          { nombre: 'Santa Catarina Mechoacán' },
          { nombre: 'Santa Catarina Minas' },
          { nombre: 'Santa Catarina Quiané' },
          { nombre: 'Santa Catarina Quioquitani' },
          { nombre: 'Santa CatarinaTayata' },
          { nombre: 'Santa Catarina Ticuá' },
          { nombre: 'Santa Catarina Yosonotú' },
          { nombre: 'Santa Catarina Zapoquila' },
          { nombre: 'Santa Cruz Acatepec' },
          { nombre: 'Santa Cruz Amilpas' },
          { nombre: 'Santa Cruz de Bravo' },
          { nombre: 'Santa Cruz Itundujia' },
          { nombre: 'Santa Cruz Mixtepec' },
          { nombre: 'Santa Cruz Nundaco' },
          { nombre: 'Santa Cruz Papalutla' },
          { nombre: 'Santa Cruz Tacache de Mina' },
          { nombre: 'Santa Cruz Tacahua' },
          { nombre: 'Santa Cruz Tayata' },
          { nombre: 'Santa Cruz Xitla' },
          { nombre: 'Santa Cruz Xoxocotlán' },
          { nombre: 'Santa Cruz Zenzontepec' },
          { nombre: 'Santa Gertrudis' },
          { nombre: 'Santa Inés del Monte' },
          { nombre: 'Santa Inés de Zaragoza' },
          { nombre: 'Santa Inés Yatzeche' },
          { nombre: 'Santa Lucía del Camino' },
          { nombre: 'Santa Lucía Miahuatlán' },
          { nombre: 'Santa Lucía Monteverde' },
          { nombre: 'Santa Lucía Ocotlán' },
          { nombre: 'Santa Magdalena Jicotlán' },
          { nombre: 'Santa María Alotepec' },
          { nombre: 'Santa María Apazco' },
          { nombre: 'Santa María Atzompa' },
          { nombre: 'Santa María Camotlán' },
          { nombre: 'Santa María Chachoápam' },
          { nombre: 'Santa María Chilchotla' },
          { nombre: 'Santa María Chimalapa' },
          { nombre: 'Santa María Colotepec' },
          { nombre: 'Santa María Cortijo' },
          { nombre: 'Santa María Coyotepec' },
          { nombre: 'Santa María del Rosario' },
          { nombre: 'Santa María del Tule' },
          { nombre: 'Santa María Ecatepec' },
          { nombre: 'Santa María Guelacé' },
          { nombre: 'Santa María Guienagati' },
          { nombre: 'Santa María Huatulco' },
          { nombre: 'Santa María Huazolotitlán' },
          { nombre: 'Santa María Ipalapa' },
          { nombre: 'Santa María Ixcatlán' },
          { nombre: 'Santa María Jacatepec' },
          { nombre: 'Santa María Jalapa del Marqués' },
          { nombre: 'Santa María Jaltianguis' },
          { nombre: 'Santa María la Asunción' },
          { nombre: 'Santa María Lachixío' },
          { nombre: 'Santa María Mixtequilla' },
          { nombre: 'Santa María Nativitas' },
          { nombre: 'Santa María Nduayaco' },
          { nombre: 'Santa María Ozolotepec' },
          { nombre: 'Santa María Pápalo' },
          { nombre: 'Santa María Peñoles' },
          { nombre: 'Santa María Petapa' },
          { nombre: 'Santa María Quiegolani' },
          { nombre: 'Santa María Sola' },
          { nombre: 'Santa María Tataltepec' },
          { nombre: 'Santa María Tecomavaca' },
          { nombre: 'Santa María Temaxcalapa' },
          { nombre: 'Santa María Temaxcaltepec' },
          { nombre: 'Santa María Teopoxco' },
          { nombre: 'Santa María Tepantlali' },
          { nombre: 'Santa María Texcatitlán' },
          { nombre: 'Santa María Tlahuitoltepec' },
          { nombre: 'Santa María Tlalixtac' },
          { nombre: 'Santa María Tonameca' },
          { nombre: 'Santa María Totolapilla' },
          { nombre: 'Santa María Xadani' },
          { nombre: 'Santa María Yalina' },
          { nombre: 'Santa María Yavesía' },
          { nombre: 'Santa María Yolotepec' },
          { nombre: 'Santa María Yosoyua' },
          { nombre: 'Santa María Yucuhiti' },
          { nombre: 'Santa María Zacatepec' },
          { nombre: 'Santa María Zaniza' },
          { nombre: 'Santa María Zoquitlán' },
          { nombre: 'Santiago Amoltepec' },
          { nombre: 'Santiago Apoala' },
          { nombre: 'Santiago Apóstol' },
          { nombre: 'Santiago Astata' },
          { nombre: 'Santiago Atitlán' },
          { nombre: 'Santiago Ayuquililla' },
          { nombre: 'Santiago Cacaloxtepec' },
          { nombre: 'Santiago Camotlán' },
          { nombre: 'Santiago Chazumba' },
          { nombre: 'Santiago Choápam' },
          { nombre: 'Santiago Comaltepec' },
          { nombre: 'Santiago del Río' },
          { nombre: 'Santiago Huajolotitlán' },
          { nombre: 'Santiago Huauclilla' },
          { nombre: 'Santiago Ihuitlán Plumas' },
          { nombre: 'Santiago Ixcuintepec' },
          { nombre: 'Santiago Ixtayutla' },
          { nombre: 'Santiago Jamiltepec' },
          { nombre: 'Santiago Jocotepec' },
          { nombre: 'Santiago Juxtlahuaca' },
          { nombre: 'Santiago Lachiguiri' },
          { nombre: 'Santiago Lalopa' },
          { nombre: 'Santiago Laollaga' },
          { nombre: 'Santiago Laxopa' },
          { nombre: 'Santiago Llano Grande' },
          { nombre: 'Santiago Matatlán' },
          { nombre: 'Santiago Miltepec' },
          { nombre: 'Santiago Minas' },
          { nombre: 'Santiago Nacaltepec' },
          { nombre: 'Santiago Nejapilla' },
          { nombre: 'Santiago Niltepec' },
          { nombre: 'Santiago Nundiche' },
          { nombre: 'Santiago Nuyoó' },
          { nombre: 'Santiago Pinotepa Nacional' },
          { nombre: 'Santiago Suchilquitongo' },
          { nombre: 'Santiago Tamazola' },
          { nombre: 'Santiago Tapextla' },
          { nombre: 'Santiago Tenango' },
          { nombre: 'Santiago Tepetlapa' },
          { nombre: 'Santiago Tetepec' },
          { nombre: 'Santiago Texcalcingo' },
          { nombre: 'Santiago Textitlán' },
          { nombre: 'Santiago Tilantongo' },
          { nombre: 'Santiago Tillo' },
          { nombre: 'Santiago Tlazoyaltepec' },
          { nombre: 'Santiago Xanica' },
          { nombre: 'Santiago Xiacuí' },
          { nombre: 'Santiago Yaitepec' },
          { nombre: 'Santiago Yaveo' },
          { nombre: 'Santiago Yolomécatl' },
          { nombre: 'Santiago Yosondúa' },
          { nombre: 'Santiago Yucuyachi' },
          { nombre: 'Santiago Zacatepec' },
          { nombre: 'Santiago Zoochila' },
          { nombre: 'Santo Domingo Albarradas' },
          { nombre: 'Santo Domingo Armenta' },
          { nombre: 'Santo Domingo Chihuitán' },
          { nombre: 'Santo Domingo de Morelos' },
          { nombre: 'Santo Domingo Ingenio' },
          { nombre: 'Santo Domingo Ixcatlán' },
          { nombre: 'Santo Domingo Nuxaá' },
          { nombre: 'Santo Domingo Ozolotepec' },
          { nombre: 'Santo Domingo Petapa' },
          { nombre: 'Santo Domingo Roayaga' },
          { nombre: 'Santo Domingo Tehuantepec' },
          { nombre: 'Santo Domingo Teojomulco' },
          { nombre: 'Santo Domingo Tepuxtepec' },
          { nombre: 'Santo Domingo Tlatayapam' },
          { nombre: 'Santo Domingo Tomaltepec' },
          { nombre: 'Santo Domingo Tonalá' },
          { nombre: 'Santo Domingo Tonaltepec' },
          { nombre: 'Santo Domingo Xagacía' },
          { nombre: 'Santo Domingo Yanhuitlán' },
          { nombre: 'Santo Domingo Yodohino' },
          { nombre: 'Santo Domingo Zanatepec' },
          { nombre: 'Santo Tomás Jalieza' },
          { nombre: 'Santo Tomás Mazaltepec' },
          { nombre: 'Santo Tomás Ocotepec' },
          { nombre: 'Santo Tomás Tamazulapan' },
          { nombre: 'Santos Reyes Nopala' },
          { nombre: 'Santos Reyes Pápalo' },
          { nombre: 'Santos Reyes Tepejillo' },
          { nombre: 'Santos Reyes Yucuná' },
          { nombre: 'San Vicente Coatlán' },
          { nombre: 'San Vicente Lachixío' },
          { nombre: 'San Vicente Nuñú' },
          { nombre: 'Silacayoápam' },
          { nombre: 'Sitio de Xitlapehua' },
          { nombre: 'Soledad Etla' },
          { nombre: 'Tamazulápam del Espíritu Santo' },
          { nombre: 'Tanetze de Zaragoza' },
          { nombre: 'Taniche' },
          { nombre: 'Tataltepec de Valdés' },
          { nombre: 'Teococuilco de Marcos Pérez' },
          { nombre: 'Teotitlán de Flores Magón' },
          { nombre: 'Teotitlán del Valle' },
          { nombre: 'Teotongo' },
          { nombre: 'Tepelmeme Villa de Morelos' },
          { nombre: 'Tezoatlán de Segura y Luna' },
          { nombre: 'Tlacolula de Matamoros' },
          { nombre: 'Tlacotepec Plumas' },
          { nombre: 'Tlalixtac de Cabrera' },
          { nombre: 'Totontepec Villa de Morelos' },
          { nombre: 'Trinidad Zaáchila' },
          { nombre: 'Unión Hidalgo' },
          { nombre: 'Valerio Trujano' },
          { nombre: 'Villa de Chilapa de Díaz' },
          { nombre: 'Villa de Etla' },
          { nombre: 'Villa de Tamazulápam del Progreso' },
          { nombre: 'Villa de Tututepec de Melchor Ocampo' },
          { nombre: 'Villa de Zaáchila' },
          { nombre: 'Villa Díaz Ordaz' },
          { nombre: 'Villa Hidalgo' },
          { nombre: 'Villa Sola de Vega' },
          { nombre: 'Villa Talea de Castro' },
          { nombre: 'Villa Tejupam de la Unión' },
          { nombre: 'Yaxe' },
          { nombre: 'Yogana' },
          { nombre: 'Yutanduchi de Guerrero' },
          { nombre: 'Zapotitlán del Río' },
          { nombre: 'Zapotitlán Lagunas' },
          { nombre: 'Zapotitlán Palmas' },
          { nombre: 'Zimatlán de Álvarez' }
        ]
      }

      if (this.estado === 'Puebla') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acajete' },
          { nombre: 'Acateno' },
          { nombre: 'Acatlán' },
          { nombre: 'Acatzingo' },
          { nombre: 'Acteopan' },
          { nombre: 'Ahuacatlán' },
          { nombre: 'Ahuatlán' },
          { nombre: 'Ahuazotepec' },
          { nombre: 'Ahuehuetitla' },
          { nombre: 'Ajalpan' },
          { nombre: 'Albino Zertuche' },
          { nombre: 'Aljojuca' },
          { nombre: 'Altepexi' },
          { nombre: 'Amixtlán' },
          { nombre: 'Amozoc' },
          { nombre: 'Aquixtla' },
          { nombre: 'Atempan' },
          { nombre: 'Atexcal' },
          { nombre: 'Atlequizayan' },
          { nombre: 'Atlixco' },
          { nombre: 'Atoyatempan' },
          { nombre: 'Atzala' },
          { nombre: 'Atzitzihuacán' },
          { nombre: 'Atzitzintla' },
          { nombre: 'Axutla' },
          { nombre: 'Ayotoxco de Guerrero' },
          { nombre: 'Calpan' },
          { nombre: 'Caltepec' },
          { nombre: 'Camocuautla' },
          { nombre: 'Cañada Morelos' },
          { nombre: 'Caxhuacan' },
          { nombre: 'Chalchicomula de Sesma' },
          { nombre: 'Chapulco' },
          { nombre: 'Chiautla' },
          { nombre: 'Chiautzingo' },
          { nombre: 'Chichiquila' },
          { nombre: 'Chiconcuautla' },
          { nombre: 'Chietla' },
          { nombre: 'Chigmecatitlán' },
          { nombre: 'Chignahuapan' },
          { nombre: 'Chignautla' },
          { nombre: 'Chila' },
          { nombre: 'Chila de la Sal' },
          { nombre: 'Chilchotla' },
          { nombre: 'Chinantla' },
          { nombre: 'Coatepec' },
          { nombre: 'Coatzingo' },
          { nombre: 'Cohetzala' },
          { nombre: 'Cohuecan' },
          { nombre: 'Coronango' },
          { nombre: 'Coxcatlán' },
          { nombre: 'Coyomeapan' },
          { nombre: 'Coyotepec' },
          { nombre: 'Cuapiaxtla de Madero' },
          { nombre: 'Cuautempan' },
          { nombre: 'Cuautinchán' },
          { nombre: 'Cuautlancingo' },
          { nombre: 'Cuayuca de Andradre' },
          { nombre: 'Cuetzalan del Progreso' },
          { nombre: 'Cuyoaco' },
          { nombre: 'Domingo Arenas' },
          { nombre: 'Eloxochitlán' },
          { nombre: 'Epatlán' },
          { nombre: 'Esperanza' },
          { nombre: 'Francisco Z. Mena' },
          { nombre: 'General Felipe Ángeles' },
          { nombre: 'Guadalupe' },
          { nombre: 'Guadalupe Victoria' },
          { nombre: 'Hermenegildo Galeana' },
          { nombre: 'Honey' },
          { nombre: 'Huaquechula' },
          { nombre: 'Huatlatlauca' },
          { nombre: 'Huauchinango' },
          { nombre: 'Huehuetla' },
          { nombre: 'Huehuetlán el Chico' },
          { nombre: 'Huehuetlán el Grande' },
          { nombre: 'Huejotzingo' },
          { nombre: 'Hueyapan' },
          { nombre: 'Hueytamalco' },
          { nombre: 'Hueytlalpan' },
          { nombre: 'Huitzilán de Serdán' },
          { nombre: 'Huitziltepec' },
          { nombre: 'Ixcamilpa de Guerrero' },
          { nombre: 'Ixcaquixtla' },
          { nombre: 'Ixtacamaxtitlán' },
          { nombre: 'Ixtepec' },
          { nombre: 'Izúcar de Matamoros' },
          { nombre: 'Jalpan' },
          { nombre: 'Jolalpan' },
          { nombre: 'Jonotla' },
          { nombre: 'Jopala' },
          { nombre: 'Juan C. Bonilla' },
          { nombre: 'Juan Galindo' },
          { nombre: 'Juan N. Méndez' },
          { nombre: 'Lafragua' },
          { nombre: 'Libres' },
          { nombre: 'Los Reyes de Juárez' },
          { nombre: 'Magdalena Tlatlauquitepec' },
          { nombre: 'Mazapiltepec de Juárez' },
          { nombre: 'Mixtla' },
          { nombre: 'Molcaxac' },
          { nombre: 'Naupan' },
          { nombre: 'Nauzontla' },
          { nombre: 'Nealtican' },
          { nombre: 'Nicolás Bravo' },
          { nombre: 'Nopalucan' },
          { nombre: 'Ocotepec' },
          { nombre: 'Ocoyucan' },
          { nombre: 'Olintla' },
          { nombre: 'Oriental' },
          { nombre: 'Pahuatlán' },
          { nombre: 'Palmar de Bravo' },
          { nombre: 'Pantepec' },
          { nombre: 'Petlalcingo' },
          { nombre: 'Piaxtla' },
          { nombre: 'Puebla de Zaragoza' },
          { nombre: 'Quecholac' },
          { nombre: 'Quimixtlán' },
          { nombre: 'Rafael Lara Grajales' },
          { nombre: 'San Andrés Cholula' },
          { nombre: 'San Antonio Cañada' },
          { nombre: 'San Diego La Meza Tochimiltzingo' },
          { nombre: 'San Felipe Teotlalcingo' },
          { nombre: 'San Felipe Tepatlán' },
          { nombre: 'San Gabriel Chilac' },
          { nombre: 'San Gregorio Atzompa' },
          { nombre: 'San Jerónimo Tecuanipan' },
          { nombre: 'San Jerónimo Xayacatlán' },
          { nombre: 'San José Chiapa' },
          { nombre: 'San José Miahuatlán' },
          { nombre: 'San Juan Atenco' },
          { nombre: 'San Juan Atzompa' },
          { nombre: 'San Martín Texmelucan' },
          { nombre: 'San Martín Totoltepec' },
          { nombre: 'San Matías Tlalancaleca' },
          { nombre: 'San Miguel Ixtitlán' },
          { nombre: 'San Miguel Xoxtla' },
          { nombre: 'San Nicolás Buenos Aires' },
          { nombre: 'San Nicolás de los Ranchos' },
          { nombre: 'San Pablo Anicano' },
          { nombre: 'San Pedro Cholula' },
          { nombre: 'San Pedro Yeloixtlahuaca' },
          { nombre: 'San Salvador el Seco' },
          { nombre: 'San Salvador el Verde' },
          { nombre: 'San Salvador Huixcolotla' },
          { nombre: 'San Sebastián Tlacotepec' },
          { nombre: 'Santa Catarina Tlaltempan' },
          { nombre: 'San Inés Ahuatempan' },
          { nombre: 'Santa Isabel Cholula' },
          { nombre: 'Santiago Miahuatlán' },
          { nombre: 'Santo Tomás Hueyotlipan' },
          { nombre: 'Soltepec' },
          { nombre: 'Tecali de Herrera' },
          { nombre: 'Tecamachalco' },
          { nombre: 'Tecomatlán' },
          { nombre: 'Tehuacán' },
          { nombre: 'Tehuitzingo' },
          { nombre: 'Tenampulco' },
          { nombre: 'Teopantlán' },
          { nombre: 'Teotlalco' },
          { nombre: 'Tepanco de López' },
          { nombre: 'Tepango de Rodríguez' },
          { nombre: 'Tepatlaxco de Hidalgo' },
          { nombre: 'Tepeaca' },
          { nombre: 'Tepemaxalco' },
          { nombre: 'Tepeojuma' },
          { nombre: 'Tepetzintla' },
          { nombre: 'Tepexco' },
          { nombre: 'Tepexi de Rodríguez' },
          { nombre: 'Tepeyahualco' },
          { nombre: 'Tepeyahualco de Cuauhtémoc' },
          { nombre: 'Tetela de Ocampo' },
          { nombre: 'Teteles de Ávila Castillo' },
          { nombre: 'Teziutlán' },
          { nombre: 'Tianguismanalco' },
          { nombre: 'Tilapa' },
          { nombre: 'Tlacotepec de Benito Juárez' },
          { nombre: 'Tlacuilotepec' },
          { nombre: 'Tlachichuca' },
          { nombre: 'Tlahuapan' },
          { nombre: 'Tlaltenango' },
          { nombre: 'Tlanepantla' },
          { nombre: 'Tlaola' },
          { nombre: 'Tlapacoya' },
          { nombre: 'Tlapanalá' },
          { nombre: 'Tlatlauquitepec' },
          { nombre: 'Tlaxco' },
          { nombre: 'Tochimilco' },
          { nombre: 'Tochtepec' },
          { nombre: 'Totoltepec de Guerrero' },
          { nombre: 'Tulcingo' },
          { nombre: 'Tuzamapan de Galeana' },
          { nombre: 'Tzicatlacoyan' },
          { nombre: 'Venustiano Carranza' },
          { nombre: 'Vicente Guerrero' },
          { nombre: 'Xayacatlán de Bravo' },
          { nombre: 'Xicotepec' },
          { nombre: 'Xicotlán' },
          { nombre: 'Xiutetelco' },
          { nombre: 'Xochiapulco' },
          { nombre: 'Xochiltepec' },
          { nombre: 'Xochitlán de Vicente Suárez' },
          { nombre: 'Xochitlán Todos Santos' },
          { nombre: 'Yaonahuac' },
          { nombre: 'Yehualtepec' },
          { nombre: 'Zacapala' },
          { nombre: 'Zacapoaxtla' },
          { nombre: 'Zacatlán' },
          { nombre: 'Zapotitlán' },
          { nombre: 'Zapotitlán de Méndez' },
          { nombre: 'Zaragoza' },
          { nombre: 'Zautla' },
          { nombre: 'Zihuateutla' },
          { nombre: 'Zinacatepec' },
          { nombre: 'Zongozotla' },
          { nombre: 'Zoquiapan' },
          { nombre: 'Zoquitlán' }
        ]
      }

      if (this.estado === 'Querétaro') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Amealco de Bonfil' },
          { nombre: 'Arroyo Seco' },
          { nombre: 'Cadereyta de Montes' },
          { nombre: 'Colón' },
          { nombre: 'Corregidora' },
          { nombre: 'El Marqués' },
          { nombre: 'Ezequiel Montes' },
          { nombre: 'Huimilpan' },
          { nombre: 'Jalpan de Serra' },
          { nombre: 'Landa de Matamoros' },
          { nombre: 'Pedro Escobedo' },
          { nombre: 'Peñamiller' },
          { nombre: 'Pinal de Amoles' },
          { nombre: 'Querétaro' },
          { nombre: 'San Joaquín' },
          { nombre: 'San Juan del Río' },
          { nombre: 'Tequisquiapan' },
          { nombre: 'Tolimán' }
        ]
      }

      if (this.estado === 'Quintana Roo') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Benito Juárez' },
          { nombre: 'Cozumel' },
          { nombre: 'Felipe Carrillo Puerto' },
          { nombre: 'Isla Mujeres' },
          { nombre: 'José María Morelos' },
          { nombre: 'Lázaro Cárdenas' },
          { nombre: 'Othón P. Blanco' },
          { nombre: 'Solidaridad' },
          { nombre: 'Tulum' },

        ]
      }

      if (this.estado === 'Quintana Roo') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Benito Juárez' },
          { nombre: 'Cozumel' },
          { nombre: 'Felipe Carrillo Puerto' },
          { nombre: 'Isla Mujeres' },
          { nombre: 'José María Morelos' },
          { nombre: 'Lázaro Cárdenas' },
          { nombre: 'Othón P. Blanco' },
          { nombre: 'Solidaridad' },
          { nombre: 'Tulum' }
        ]
      }

      if (this.estado === 'San Luis Potosí') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Ahualulco' },
          { nombre: 'Alaquines' },
          { nombre: 'Aquismón' },
          { nombre: 'Armadillo de los Infante' },
          { nombre: 'Axtla de Terrazas' },
          { nombre: 'Cárdenas' },
          { nombre: 'Catorce' },
          { nombre: 'Cedral' },
          { nombre: 'Cerritos' },
          { nombre: 'Cerro de San Pedro' },
          { nombre: 'Charcas' },
          { nombre: 'Ciudad del Maíz' },
          { nombre: 'Ciudad Fernández' },
          { nombre: 'Ciudad Valles' },
          { nombre: 'Coxcatlán' },
          { nombre: 'Ebano' },
          { nombre: 'El Naranjo' },
          { nombre: 'Guadalcázar' },
          { nombre: 'Huehuetlán' },
          { nombre: 'Lagunillas' },
          { nombre: 'Matehuala' },
          { nombre: 'Matlapa' },
          { nombre: 'Mexquitic de Carmona' },
          { nombre: 'Moctezuma' },
          { nombre: 'Rayón' },
          { nombre: 'Rioverde' },
          { nombre: 'Salinas' },
          { nombre: 'San Antonio' },
          { nombre: 'San Ciro de Acosta' },
          { nombre: 'San Luis Potosí' },
          { nombre: 'San Martín Chalchicuautla' },
          { nombre: 'San Nicolás Tolentino' },
          { nombre: 'Santa Catarina' },
          { nombre: 'Santa María del Río' },
          { nombre: 'Santo Domingo' },
          { nombre: 'San Vicente Tancuayalab' },
          { nombre: 'Soledad de Graciano Sánchez' },
          { nombre: 'Tamasopo' },
          { nombre: 'Tamazunchale' },
          { nombre: 'Tampacán' },
          { nombre: 'Tampamolón Corona' },
          { nombre: 'Tamuín' },
          { nombre: 'Tancanhuitz de Santos' },
          { nombre: 'Tanlajás' },
          { nombre: 'Tanquián de Escobedo' },
          { nombre: 'Tierra Nueva' },
          { nombre: 'Vanegas' },
          { nombre: 'Venado' },
          { nombre: 'Villa de Arriaga' },
          { nombre: 'Villa de Arista' },
          { nombre: 'Villa de Guadalupe' },
          { nombre: 'Villa de la Paz' },
          { nombre: 'Villa de Ramos' },
          { nombre: 'Villa de Reyes' },
          { nombre: 'Villa Hidalgo' },
          { nombre: 'Villa Juárez' },
          { nombre: 'Xilitla' },
          { nombre: 'Zaragoza' }
        ]
      }

      if (this.estado === 'Sinaloa') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Ahome' },
          { nombre: 'Angostura' },
          { nombre: 'Badiraguato' },
          { nombre: 'Choix' },
          { nombre: 'Concordia' },
          { nombre: 'Cosalá' },
          { nombre: 'Culiacán' },
          { nombre: 'El Fuerte' },
          { nombre: 'Elota' },
          { nombre: 'El Rosario' },
          { nombre: 'Escuinapa' },
          { nombre: 'Guasave' },
          { nombre: 'Mazatlán' },
          { nombre: 'Mocorito' },
          { nombre: 'Navolato' },
          { nombre: 'Salvador Alvarado' },
          { nombre: 'San Ignacio' },
          { nombre: 'Sinaloa de Leyva' }
        ]
      }

      if (this.estado === 'Sonora') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Aconchi' },
          { nombre: 'Agua Prieta' },
          { nombre: 'Alamos' },
          { nombre: 'Altar' },
          { nombre: 'Arivechi' },
          { nombre: 'Arizpe' },
          { nombre: 'Atil' },
          { nombre: 'Bacadéhuachi' },
          { nombre: 'Bacanora' },
          { nombre: 'Bacerac' },
          { nombre: 'Bacoachi' },
          { nombre: 'Bácum' },
          { nombre: 'Banámichi' },
          { nombre: 'Baviácora' },
          { nombre: 'Bavíspe' },
          { nombre: 'Benito Juárez' },
          { nombre: 'Benjamín Hill' },
          { nombre: 'Caborca' },
          { nombre: 'Cajeme' },
          { nombre: 'Cananea' },
          { nombre: 'Carbó' },
          { nombre: 'Cocurpe' },
          { nombre: 'Cumpas' },
          { nombre: 'Divisaderos' },
          { nombre: 'Empalme' },
          { nombre: 'Etchojoa' },
          { nombre: 'Fronteras' },
          { nombre: 'General Plutarco Elías Calles' },
          { nombre: 'Granados' },
          { nombre: 'Guaymas' },
          { nombre: 'Hermosillo' },
          { nombre: 'Huachinera' },
          { nombre: 'Huásabas' },
          { nombre: 'Huatabampo' },
          { nombre: 'Huépac' },
          { nombre: 'Imuris' },
          { nombre: 'La Colorada' },
          { nombre: 'Magdalena' },
          { nombre: 'Mazatán' },
          { nombre: 'Moctezuma' },
          { nombre: 'Naco' },
          { nombre: 'Nácori Chico' },
          { nombre: 'Nacozari de García' },
          { nombre: 'Navojoa' },
          { nombre: 'Nogales' },
          { nombre: 'Onavas' },
          { nombre: 'Opodepe' },
          { nombre: 'Oquitoa' },
          { nombre: 'Pitiquito' },
          { nombre: 'Puerto Peñasco' },
          { nombre: 'Quiriego' },
          { nombre: 'Rayón' },
          { nombre: 'Rosario' },
          { nombre: 'Sahuaripa' },
          { nombre: 'San Felipe de Jesús' },
          { nombre: 'San Ignacio Río Muerto' },
          { nombre: 'San Javier' },
          { nombre: 'San Luis Río Colorado' },
          { nombre: 'San Miguel de Horcasitas' },
          { nombre: 'San Pedro de la Cueva' },
          { nombre: 'Santa Ana' },
          { nombre: 'Santa Cruz' },
          { nombre: 'Sáric' },
          { nombre: 'Soyopa' },
          { nombre: 'Suaqui Grande' },
          { nombre: 'Tepache' },
          { nombre: 'Trincheras' },
          { nombre: 'Tubutama' },
          { nombre: 'Ures' },
          { nombre: 'Villa Hidalgo' },
          { nombre: 'Villa Pesqueira' },
          { nombre: 'Yécora' }
        ]
      }

      if (this.estado === 'Tabasco') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Balancán' },
          { nombre: 'Cárdenas' },
          { nombre: 'Centla' },
          { nombre: 'Centro' },
          { nombre: 'Comalcalco' },
          { nombre: 'Cunduacán' },
          { nombre: 'Emiliano Zapata' },
          { nombre: 'Huimanguillo' },
          { nombre: 'Jalapa' },
          { nombre: 'Jalpa de Méndez' },
          { nombre: 'Jonuta' },
          { nombre: 'Macuspana' },
          { nombre: 'Nacajuca' },
          { nombre: 'Paraíso' },
          { nombre: 'Tacotalpa' },
          { nombre: 'Teapa' },
          { nombre: 'Tenosique' }
        ]
      }

      if (this.estado === 'Tamaulipas') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Abasolo' },
          { nombre: 'Aldama' },
          { nombre: 'Altamira' },
          { nombre: 'Antiguo Morelos' },
          { nombre: 'Burgos' },
          { nombre: 'Bustamante' },
          { nombre: 'Camargo' },
          { nombre: 'Casas' },
          { nombre: 'Ciudad Madero' },
          { nombre: 'Cruillas' },
          { nombre: 'Gómez Farías' },
          { nombre: 'González' },
          { nombre: 'Güemez' },
          { nombre: 'Guerrero' },
          { nombre: 'Gustavo Díaz Ordaz' },
          { nombre: 'Hidalgo' },
          { nombre: 'Jaumave' },
          { nombre: 'Jiménez' },
          { nombre: 'Llera' },
          { nombre: 'Mainero' },
          { nombre: 'Mante' },
          { nombre: 'Matamoros' },
          { nombre: 'Méndez' },
          { nombre: 'Mier' },
          { nombre: 'Miguel Alemán' },
          { nombre: 'Miquihuana' },
          { nombre: 'Nuevo Laredo' },
          { nombre: 'Nuevo Morelos' },
          { nombre: 'Ocampo' },
          { nombre: 'Padilla' },
          { nombre: 'Palmillas' },
          { nombre: 'Reynosa' },
          { nombre: 'Río Bravo' },
          { nombre: 'San Carlos' },
          { nombre: 'San Fernando' },
          { nombre: 'San Nicolás' },
          { nombre: 'Soto La Marina' },
          { nombre: 'Tampico' },
          { nombre: 'Tula' },
          { nombre: 'Valle Hermoso' },
          { nombre: 'Victoria' },
          { nombre: 'Villagrán' },
          { nombre: 'Xicotencatl' }
        ]
      }

      if (this.estado === 'Tlaxcala') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acuamanala de Miguel Hidalgo' },
          { nombre: 'Altzayanca' },
          { nombre: 'Amaxac de Guerrero' },
          { nombre: 'Apetatitlán de Antonio Carvajal' },
          { nombre: 'Atlangatepec' },
          { nombre: 'Apizaco' },
          { nombre: 'Benito Juárez' },
          { nombre: 'Calpulalpan' },
          { nombre: 'Chiautempan' },
          { nombre: 'Contla de Juan Cuamatzi' },
          { nombre: 'Cuapiaxtla' },
          { nombre: 'Cuaxomulco' },
          { nombre: 'El Carmen Tequexquitla' },
          { nombre: 'Emiliano Zapata' },
          { nombre: 'Españita' },
          { nombre: 'Huamantla' },
          { nombre: 'Hueyotlipan' },
          { nombre: 'Ixtacuixtla de Mariano Matamoros' },
          { nombre: 'Ixtenco' },
          { nombre: 'La Magdalena Tlaltelulco' },
          { nombre: 'Lázaro Cárdenas' },
          { nombre: 'Mazatecochco de José María Morelos' },
          { nombre: 'Muñoz de Domingo Arenas' },
          { nombre: 'Nanacamilpa de Mariano Arista' },
          { nombre: 'Nativitas' },
          { nombre: 'Panotla' },
          { nombre: 'Papalotla de Xicohténcatl' },
          { nombre: 'Sanctorum de Lázaro Cárdenas' },
          { nombre: 'San Damián Texoloc' },
          { nombre: 'San Francisco Tetlanohcan' },
          { nombre: 'San Jerónimo Zacualpan' },
          { nombre: 'San José Teacalco' },
          { nombre: 'San Juan Huactzinco' },
          { nombre: 'San Lorenzo Axocomanitla' },
          { nombre: 'San Lucas Tecopilco' },
          { nombre: 'San Pablo del Monte' },
          { nombre: 'Santa Ana Nopalucan' },
          { nombre: 'Santa Apolonia Teacalco' },
          { nombre: 'Santa Catarina Ayometla' },
          { nombre: 'Santa Cruz Quilehtla' },
          { nombre: 'Santa Cruz Tlaxcala' },
          { nombre: 'Santa Isabel Xiloxoxtla' },
          { nombre: 'Tenancingo' },
          { nombre: 'Teolocholco' },
          { nombre: 'Tepetitla de Lardizábal' },
          { nombre: 'Tepeyanco' },
          { nombre: 'Terrenate' },
          { nombre: 'Tetla de la Solidaridad' },
          { nombre: 'Tetlatlahuca' },
          { nombre: 'Tlaxcala' },
          { nombre: 'Tlaxco' },
          { nombre: 'Tocatlán' },
          { nombre: 'Totolac' },
          { nombre: 'Tzompantepec' },
          { nombre: 'Xaloztoc' },
          { nombre: 'Xaltocan' },
          { nombre: 'Xicohtzinco' },
          { nombre: 'Yauhquemecan' },
          { nombre: 'Zacatelco' },
          { nombre: 'Zitlaltepec de Trinidad Sánchez Santos' }
        ]
      }

      if (this.estado === 'Veracruz') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Acajete' },
          { nombre: 'Acatlán' },
          { nombre: 'Acayucan' },
          { nombre: 'Actopan' },
          { nombre: 'Acula' },
          { nombre: 'Acultzingo' },
          { nombre: 'Agua Dulce' },
          { nombre: 'Álamo Temapache' },
          { nombre: 'Alpatláhuac' },
          { nombre: 'Alto Lucero de Gutiérrez Barrios' },
          { nombre: 'Altotonga' },
          { nombre: 'Alvarado' },
          { nombre: 'Amatitlán' },
          { nombre: 'Amatlán de los Reyes' },
          { nombre: 'Ángel R. Cabada' },
          { nombre: 'Apazapan' },
          { nombre: 'Aquila' },
          { nombre: 'Astacinga' },
          { nombre: 'Atlahuilco' },
          { nombre: 'Atoyac' },
          { nombre: 'Atzacan' },
          { nombre: 'Atzalan' },
          { nombre: 'Ayahualulco' },
          { nombre: 'Banderilla' },
          { nombre: 'Benito Juárez' },
          { nombre: 'Boca del Río' },
          { nombre: 'Calcahualco' },
          { nombre: 'Camarón de Tejeda' },
          { nombre: 'Camerino Z. Mendoza' },
          { nombre: 'Carlos A. Carrillo' },
          { nombre: 'Carrillo Puerto' },
          { nombre: 'Castillo de Teayo' },
          { nombre: 'Catemaco' },
          { nombre: 'Cazones de Herrera' },
          { nombre: 'Cerro Azul' },
          { nombre: 'Chacaltianguis' },
          { nombre: 'Chalma' },
          { nombre: 'Chiconamel' },
          { nombre: 'Chiconquiaco' },
          { nombre: 'Chicontepec' },
          { nombre: 'Chinameca' },
          { nombre: 'Chinampa de Gorostiza' },
          { nombre: 'Chocamán' },
          { nombre: 'Chontla' },
          { nombre: 'Chumatlán' },
          { nombre: 'Citlaltépetl' },
          { nombre: 'Coacoatzintla' },
          { nombre: 'Coahuitlán' },
          { nombre: 'Coatepec' },
          { nombre: 'Coatzacoalcos' },
          { nombre: 'Coatzintla' },
          { nombre: 'Coetzala' },
          { nombre: 'Colipa' },
          { nombre: 'Comapa' },
          { nombre: 'Córdoba' },
          { nombre: 'Cosamaloapan de Carpio' },
          { nombre: 'Consautlán de Carvajal' },
          { nombre: 'Coscomatepec' },
          { nombre: 'Cosoleacaque' },
          { nombre: 'Cotaxtla' },
          { nombre: 'Coxquihui' },
          { nombre: 'Coyutla' },
          { nombre: 'Cuichapa' },
          { nombre: 'Cuitláhuac' },
          { nombre: 'El Higo' },
          { nombre: 'Emiliano Zapata' },
          { nombre: 'Espinal' },
          { nombre: 'Filomeno Mata' },
          { nombre: 'Fortín' },
          { nombre: 'Gutiérrez Zamora' },
          { nombre: 'Hidalgotitlán' },
          { nombre: 'Huayacocotla' },
          { nombre: 'Hueyapan de Ocampo' },
          { nombre: 'Huiloapan de Cuauhtémoc' },
          { nombre: 'Ignacio de la Llave' },
          { nombre: 'Ilamatlán' },
          { nombre: 'Isla' },
          { nombre: 'Ixcatepec' },
          { nombre: 'Ixhuacán de los Reyes' },
          { nombre: 'Ixhuatlancillo' },
          { nombre: 'Ixhuatlán del Café' },
          { nombre: 'Ixhuatlán de Madero' },
          { nombre: 'Ixhuatlán del Sureste' },
          { nombre: 'Ixmatlahuacan' },
          { nombre: 'Ixtaczoquitlán' },
          { nombre: 'Jalacingo' },
          { nombre: 'Jalcomulco' },
          { nombre: 'Jáltipan' },
          { nombre: 'Jamapa' },
          { nombre: 'Jesús Carranza' },
          { nombre: 'Jilotepec' },
          { nombre: 'José Azueta' },
          { nombre: 'Juan Rodríguez Clara' },
          { nombre: 'Juchique de Ferrer' },
          { nombre: 'La Antigua' },
          { nombre: 'Landero y Coss' },
          { nombre: 'La Perla' },
          { nombre: 'Las Choapas' },
          { nombre: 'Las Minas' },
          { nombre: 'Las Vigas de Ramírez' },
          { nombre: 'Lerdo de Tejada' },
          { nombre: 'Los Reyes' },
          { nombre: 'Magdalena' },
          { nombre: 'Maltrata' },
          { nombre: 'Manlio Fabio Altamirano' },
          { nombre: 'Mariano Escobedo' },
          { nombre: 'Martínez de la Torre' },
          { nombre: 'Mecatlán' },
          { nombre: 'Mecayapan' },
          { nombre: 'Medellín' },
          { nombre: 'Miahuatlán' },
          { nombre: 'Minatitlán' },
          { nombre: 'Misantla' },
          { nombre: 'Mixtla de Altamirano' },
          { nombre: 'Moloacán' },
          { nombre: 'Nanchital de Lázaro Cárdenas del Río' },
          { nombre: 'Naolinco' },
          { nombre: 'Naranjal' },
          { nombre: 'Naranjos Amatlán' },
          { nombre: 'Nautla' },
          { nombre: 'Nogales' },
          { nombre: 'Oluta' },
          { nombre: 'Omealca' },
          { nombre: 'Orizaba' },
          { nombre: 'Otatitlán' },
          { nombre: 'Oteapan' },
          { nombre: 'Ozuluama de Mascañeras' },
          { nombre: 'Pajapan' },
          { nombre: 'Pánuco' },
          { nombre: 'Papantla' },
          { nombre: 'Paso del Macho' },
          { nombre: 'Paso de Ovejas' },
          { nombre: 'Perote' },
          { nombre: 'Platón Sánchez' },
          { nombre: 'Playa Vicente' },
          { nombre: 'Poza Rica de Hidalgo' },
          { nombre: 'Pueblo Viejo' },
          { nombre: 'Puente Nacional' },
          { nombre: 'Rafael Delgado' },
          { nombre: 'Rafael Lucio' },
          { nombre: 'Río Blanco' },
          { nombre: 'Saltabarranca' },
          { nombre: 'San Andrés Tenejapan' },
          { nombre: 'San Andrés Tuxtla' },
          { nombre: 'San Juan Evangelista' },
          { nombre: 'San Rafael' },
          { nombre: 'Santiago Sochiapan' },
          { nombre: 'Santiago Tuxtla' },
          { nombre: 'Sayula de Alemán' },
          { nombre: 'Soconusco' },
          { nombre: 'Sochiapa' },
          { nombre: 'Soledad Atzompa' },
          { nombre: 'Soledad de Doblado' },
          { nombre: 'Soteapan' },
          { nombre: 'Tamalín' },
          { nombre: 'Tamiahua' },
          { nombre: 'Tampico Alto' },
          { nombre: 'Tancoco' },
          { nombre: 'Tantima' },
          { nombre: 'Tantoyuca' },
          { nombre: 'Tatatila' },
          { nombre: 'Tatahuicapan de Juárez' },
          { nombre: 'Tecolutla' },
          { nombre: 'Tehuipango' },
          { nombre: 'Tempoal' },
          { nombre: 'Tenampa' },
          { nombre: 'Tenochtitlán' },
          { nombre: 'Teocelo' },
          { nombre: 'Tepatlaxco' },
          { nombre: 'Tepetlán' },
          { nombre: 'Tepetzintla' },
          { nombre: 'Tequila' },
          { nombre: 'Texcatepec' },
          { nombre: 'Texhuacán' },
          { nombre: 'Texistepec' },
          { nombre: 'Tezonapa' },
          { nombre: 'Tihuatlán' },
          { nombre: 'Tierra Blanca' },
          { nombre: 'Tlacojalpan' },
          { nombre: 'Tlacolulan' },
          { nombre: 'Tlacotalpan' },
          { nombre: 'Tlacotepec de Mejía' },
          { nombre: 'Tlachichilco' },
          { nombre: 'Tlalixcoyan' },
          { nombre: 'Tlalnelhuayocan' },
          { nombre: 'Tlaltetela' },
          { nombre: 'Tlapacoyan' },
          { nombre: 'Tlaquilpa' },
          { nombre: 'Tlilapan' },
          { nombre: 'Tomatlán' },
          { nombre: 'Tonayán' },
          { nombre: 'Totutla' },
          { nombre: 'Tres Valles' },
          { nombre: 'Tuxpan' },
          { nombre: 'Tuxtilla' },
          { nombre: 'Úrsulo Galván' },
          { nombre: 'Uxpanapa' },
          { nombre: 'Vega de Alatorre' },
          { nombre: 'Veracruz' },
          { nombre: 'Villa Aldama' },
          { nombre: 'Xalapa' },
          { nombre: 'Xico' },
          { nombre: 'Xoxocotla' },
          { nombre: 'Yanga' },
          { nombre: 'Yecuatla' },
          { nombre: 'Zacualpan' },
          { nombre: 'Zaragoza' },
          { nombre: 'Zentla' },
          { nombre: 'Zongolica' },
          { nombre: 'Zontecomatlán' },
          { nombre: 'Zozocolco de Hidalgo' }
        ]
      }

      if (this.estado === 'Yucatán') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Municipios del Estado de YucatánAbalá' },
          { nombre: 'Acanceh' },
          { nombre: 'Akil' },
          { nombre: 'Baca' },
          { nombre: 'Bokobá' },
          { nombre: 'Buctzotz' },
          { nombre: 'Cacalchén' },
          { nombre: 'Calotmul' },
          { nombre: 'Cansahcab' },
          { nombre: 'Cantamayec' },
          { nombre: 'Calestún' },
          { nombre: 'Cenotillo' },
          { nombre: 'Conkal' },
          { nombre: 'Cuncunul' },
          { nombre: 'Cuzamá' },
          { nombre: 'Chacsinkín' },
          { nombre: 'Chankom' },
          { nombre: 'Chapab' },
          { nombre: 'Chemax' },
          { nombre: 'Chicxulub Pueblo' },
          { nombre: 'Chichimilá' },
          { nombre: 'Chikindzonot' },
          { nombre: 'Chocholá' },
          { nombre: 'Chumayel' },
          { nombre: 'Dzán' },
          { nombre: 'Dzemul' },
          { nombre: 'Dzidzantún' },
          { nombre: 'Dzilam de Bravo' },
          { nombre: 'Dzilam González' },
          { nombre: 'Dzitás' },
          { nombre: 'Dzoncauich' },
          { nombre: 'Espita' },
          { nombre: 'Halachó' },
          { nombre: 'Hocabá' },
          { nombre: 'Hoctún' },
          { nombre: 'Homún' },
          { nombre: 'Huhí' },
          { nombre: 'Hunucmá' },
          { nombre: 'Ixil' },
          { nombre: 'Izamal' },
          { nombre: 'Kanasín' },
          { nombre: 'Kantunil' },
          { nombre: 'Kaua' },
          { nombre: 'Kinchil' },
          { nombre: 'Kopomá' },
          { nombre: 'Mama' },
          { nombre: 'Maní' },
          { nombre: 'Maxcanú' },
          { nombre: 'Mayapán' },
          { nombre: 'Mérida' },
          { nombre: 'Mocochá' },
          { nombre: 'Motul' },
          { nombre: 'Muna' },
          { nombre: 'Muxupip' },
          { nombre: 'Opichén' },
          { nombre: 'Oxkutzcab' },
          { nombre: 'Panabá' },
          { nombre: 'Peto' },
          { nombre: 'Progreso' },
          { nombre: 'Quintana Roo' },
          { nombre: 'Río Lagartos' },
          { nombre: 'Sacalum' },
          { nombre: 'Samahil' },
          { nombre: 'Sanahcat' },
          { nombre: 'San Felipe' },
          { nombre: 'Santa Elena' },
          { nombre: 'Seyé' },
          { nombre: 'Sinanché' },
          { nombre: 'Sotuta' },
          { nombre: 'Sucilá' },
          { nombre: 'Sudzal' },
          { nombre: 'Suma de Hidalgo' },
          { nombre: 'Tahdziú' },
          { nombre: 'Tahmek' },
          { nombre: 'Teabo' },
          { nombre: 'Tecoh' },
          { nombre: 'Tekal de Venegas' },
          { nombre: 'Tekantó' },
          { nombre: 'Tekax' },
          { nombre: 'Tekit' },
          { nombre: 'Tekom' },
          { nombre: 'Telchac Pueblo' },
          { nombre: 'Telchac Puerto' },
          { nombre: 'Temax' },
          { nombre: 'Temozón' },
          { nombre: 'Tepakán' },
          { nombre: 'Tetiz' },
          { nombre: 'Teya' },
          { nombre: 'Ticul' },
          { nombre: 'Timucuy' },
          { nombre: 'Tinúm' },
          { nombre: 'Tixcacalcupul' },
          { nombre: 'Tixkokob' },
          { nombre: 'Tixméhuac' },
          { nombre: 'Tixpéhual' },
          { nombre: 'Tizimín' },
          { nombre: 'Tunkás' },
          { nombre: 'Tzucacab' },
          { nombre: 'Uayma' },
          { nombre: 'Ucú' },
          { nombre: 'Umán' },
          { nombre: 'Valladolid' },
          { nombre: 'Xocchel' },
          { nombre: 'Yaxcabá' },
          { nombre: 'Yaxkukul' },
          { nombre: 'Yobaín' }
        ]
      }

      if (this.estado === 'Zacatecas') {
        this.cxe = '';
        this.ciudades = [
          { nombre: 'Apozol' },
          { nombre: 'Apulco' },
          { nombre: 'Atolinga' },
          { nombre: 'Benito Juárez' },
          { nombre: 'Calera' },
          { nombre: 'Cañitas de Felipe Pescador' },
          { nombre: 'Concepción del Oro' },
          { nombre: 'Cuauhtémoc' },
          { nombre: 'Chalchihuites' },
          { nombre: 'Fresnillo' },
          { nombre: 'Trinidad García de la Cadena' },
          { nombre: 'Genaro Codina' },
          { nombre: 'General Enrique Estrada' },
          { nombre: 'General Francisco R. Murguía' },
          { nombre: 'El Plateado de Joaquín Amaro' },
          { nombre: 'El Salvador' },
          { nombre: 'General Pánfilo Natera' },
          { nombre: 'Guadalupe' },
          { nombre: 'Huanusco' },
          { nombre: 'Jalpa' },
          { nombre: 'Jerez' },
          { nombre: 'Jiménez del Teul' },
          { nombre: 'Juan Aldama' },
          { nombre: 'Juchipila' },
          { nombre: 'Loreto' },
          { nombre: 'Luis Moya' },
          { nombre: 'Mazapil' },
          { nombre: 'Melchor Ocampo' },
          { nombre: 'Mezquital del Oro' },
          { nombre: 'Miguel Auza' },
          { nombre: 'Momax' },
          { nombre: 'Monte Escobedo' },
          { nombre: 'Morelos' },
          { nombre: 'Moyahua de Estrada' },
          { nombre: 'Nochistlán de Mejía' },
          { nombre: 'Noria de Ángeles' },
          { nombre: 'Ojocaliente' },
          { nombre: 'Pánuco' },
          { nombre: 'Pinos' },
          { nombre: 'Río Grande' },
          { nombre: 'Sain Alto' },
          { nombre: 'Santa María de la Paz' },
          { nombre: 'Sombrerete' },
          { nombre: 'Susticacán' },
          { nombre: 'Tabasco' },
          { nombre: 'Tepechitlán' },
          { nombre: 'Tepetongo' },
          { nombre: 'Teul de González Ortega' },
          { nombre: 'Tlaltenango de Sánchez Román' },
          { nombre: 'Trancoso' },
          { nombre: 'Valparaíso' },
          { nombre: 'Vetagrande' },
          { nombre: 'Villa de Cos' },
          { nombre: 'Villa García' },
          { nombre: 'Villa González Ortega' },
          { nombre: 'Villa Hidalgo' },
          { nombre: 'Villanueva' },
          { nombre: ' Zacatecas' }
        ]
      }
    },
    search_cat() {
      this.$router.push({
        name: "categoria",
        params: { cat: this.cat }
      })
    },
    zona() {
      this.$router.push({
        name: "zona",
        params: { estado: this.estado, ciudad: this.cxe }
      })
    },
    show_items() {
      axios.get('https://empleos123rh.com/v1/api/admin/recientes')
        .then(response => {
          this.items = response.data.result;
          console.log(this.items);
        }).catch((_error) => {
        });
    },
    empleo(uuid) {
      this.$router.push({
        name: "empleo",
        params: { id: uuid }
      })
    }
  },
  mounted() {
    this.show_items();
  },
}
</script>

<style scoped>
.btn {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.search {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0% !important;
}

.form-control-lg {
  border-radius: 0 !important;
}

header.masthead {
  padding-top: 15.5rem;
  padding-bottom: 6rem;
  text-align: center;
  color: #fff;
  background: url(../assets/header_home.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 50% 10%;
  background-size: cover;
}

header.masthead .masthead-heading {
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 3.25rem;
  margin-bottom: 2rem;
}

header.masthead .masthead-subheading {
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.5rem;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 17rem;
    padding-bottom: 12.5rem;
  }

  header.masthead .masthead-subheading {
    font-size: 2.25rem;
    font-style: italic;
    line-height: 2.25rem;
    margin-bottom: 2rem;
  }
}

.card {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

footer {
  background-color: #dc3545;
  color: white;
}

.cp {
  cursor: pointer;
}

.red {
  color: #dc3545;
}

.blanco {
  color: #fff !important;
}

#cat {
  background: rgb(3, 116, 188);
}

#reciente {
  background: rgb(249, 249, 249);
}
.fixedButton {
  position: fixed;
  bottom: 0px;
  right: -10px;
  padding: 20px;
  z-index: 9;
}

.roundedFixedBtn {
  height: 60px;
  line-height: 60px;
  width: 60px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: 	#25D366;
  color: white;
  text-align: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
</style>