
async function isAuthGuard(to, from, next){
  if (localStorage.getItem('auth_token') || localStorage.getItem('user_id') ) {
        next('/empresa/dashboard')
     }else{
       next();
     }
}

export default isAuthGuard
